import FigmaEmbed from "../../components/figma/FigmaEmbed.jsx";

const FigmaTest = () => {
  return (
    <div
      id="content"
      style={{
        width: "100%",
        maxWidth: "800px",
        margin: "auto",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FigmaEmbed embedUrl="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fdesign%2F2BzRCgHsVCz8LZ7boV4z25%2FAxelar-x-BonusBlock%3Fnode-id%3D0-1%26t%3Dq10F5fd28syMwJO4-1" />
    </div>
  );
};

export default FigmaTest;
