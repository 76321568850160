"use client";

import { Card, ListGroup } from "flowbite-react";
import { HiOutlinePencilAlt, HiCode, HiOutlineFilm } from "react-icons/hi";

const CurriculumSkillsBox = () => {
  return (
    <div className="p-4 bg-white rounded-lg dark:bg-blackthorne-700">
      <div className="flex flex-col items-start space-y-4 md:flex-row md:space-y-0 md:space-x-4">
        <Card className="flex-1 bg-blackthorne-100 dark:bg-blackthorne-500 min-w-[300px]">
          <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
            Design
          </h2>
          <ListGroup className="text-gray-900 dark:text-gray-400">
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              User Experience
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              Design System
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              Design Tokens
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              Web Design
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              Mobile Design
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              User Research
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              Wireframing
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>
              Prototyping
            </ListGroup.Item>
            <ListGroup.Item icon={HiOutlinePencilAlt}>Testing</ListGroup.Item>
          </ListGroup>
        </Card>
        <Card className="flex-1 bg-blackthorne-100 dark:bg-blackthorne-500 min-w-[300px]">
          <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
            Development
          </h2>
          <ListGroup className="text-gray-900 dark:text-gray-400">
            <ListGroup.Item icon={HiCode}>React JS</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>TailwindCSS</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>Vite</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>NextJS</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>HTML</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>CSS</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>JS</ListGroup.Item>
            <ListGroup.Item icon={HiCode}>Git</ListGroup.Item>
          </ListGroup>
        </Card>
        <Card className="flex-1 bg-blackthorne-100 dark:bg-blackthorne-500 min-w-[300px]">
          <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
            Motion Graphics
          </h2>
          <ListGroup className="text-gray-900 dark:text-gray-400">
            <ListGroup.Item icon={HiOutlineFilm}>Graphic Design</ListGroup.Item>
            <ListGroup.Item icon={HiOutlineFilm}>3D Rendering</ListGroup.Item>
            <ListGroup.Item icon={HiOutlineFilm}>WebGL</ListGroup.Item>
            <ListGroup.Item icon={HiOutlineFilm}>Texturing</ListGroup.Item>
          </ListGroup>
        </Card>
      </div>
    </div>
  );
};

export default CurriculumSkillsBox;
