// src/components/DynamicSpline.jsx

// src/components/DynamicSpline.jsx

import { useRef, useEffect } from "react";
import Spline from "@splinetool/react-spline";
import PropTypes from "prop-types";

function DynamicSpline({ isPlaying }) {
  const splineRef = useRef();

  useEffect(() => {
    if (splineRef.current) {
      splineRef.current.renderOnDemand = !isPlaying;
    }
  }, [isPlaying]);

  return (
    <Spline
      ref={splineRef}
      scene="https://prod.spline.design/xSEYywgVKPokb3bJ/scene.splinecode" // Replace with your actual Spline scene URL
    />
  );
}

DynamicSpline.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
};

export default DynamicSpline;
