import { useNavigate } from "react-router-dom";

function SquaredButtons4Controller() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <button
            className="w-full py-4 text-white bg-red-600 rounded-none hover:bg-red-700"
            onClick={() => handleNavigation("/#")}
          >
            R
          </button>
          <button
            className="w-full py-4 text-white bg-green-600 rounded-none hover:bg-green-700"
            onClick={() => handleNavigation("/#")}
          >
            G
          </button>
        </div>
        <div className="flex flex-col space-y-4">
          <button
            className="w-full py-4 text-white bg-blue-600 rounded-none hover:bg-blue-700"
            onClick={() => handleNavigation("/#")}
          >
            B
          </button>
          <button
            className="w-full py-4 text-white bg-yellow-600 rounded-none hover:bg-yellow-700"
            onClick={() => handleNavigation("/#")}
          >
            Y
          </button>
        </div>
        <div className="flex space-x-4">
          <button
            className="w-full py-4 text-white bg-red-600 rounded-none hover:bg-red-700"
            onClick={() => handleNavigation("/#")}
          >
            R
          </button>
          <button
            className="w-full py-4 text-white bg-green-600 rounded-none hover:bg-green-700"
            onClick={() => handleNavigation("/#")}
          >
            G
          </button>
        </div>
        <div className="flex flex-col space-y-4">
          <button
            className="w-full py-4 text-white bg-blue-600 rounded-none hover:bg-blue-700"
            onClick={() => handleNavigation("/#")}
          >
            B
          </button>
          <button
            className="w-full py-4 text-white bg-yellow-600 rounded-none hover:bg-yellow-700"
            onClick={() => handleNavigation("/#")}
          >
            Y
          </button>
        </div>
        <div className="flex space-x-4">
          <button
            className="w-full py-4 text-white bg-red-600 rounded-none hover:bg-red-700"
            onClick={() => handleNavigation("/#")}
          >
            R
          </button>
          <button
            className="w-full py-4 text-white bg-green-600 rounded-none hover:bg-green-700"
            onClick={() => handleNavigation("/#")}
          >
            G
          </button>
        </div>
      </div>
    </div>
  );
}

export default SquaredButtons4Controller;
