"use client";

import { Card, Button } from "flowbite-react";
import {
  FaEnvelope,
  FaTelegramPlane,
  FaMapMarkerAlt,
  FaInstagram,
  FaGithub,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import PropTypes from "prop-types";

const ContactButton = ({ icon: Icon, children, href }) => (
  <Button
    as="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center text-guinera-800 border-guinera-400 dark:text-guinera-400 dark:border-guinera-900 bg-guinera-400 dark:bg-blackthorne-600 hover:text-guinera-700 dark:hover:text-guinera-300"
  >
    <Icon className="w-5 h-5 mr-2" /> {children}
  </Button>
);

ContactButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

const Contact = () => {
  return (
    <div className="container p-4 m-32 mx-auto">
      <Card className="mb-4 border-guinera-400 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-guinera-800 dark:text-brutal-100">
          Contact Information
        </h2>
        <div className="flex flex-col items-center pb-10">
          <div className="flex flex-col w-full px-4 mt-4 space-y-2 md:flex-row md:justify-center md:space-x-4 md:space-y-0 md:mt-6">
            <ContactButton icon={FaEnvelope} href="mailto:#">
              Pls dont spam me
            </ContactButton>
            <ContactButton
              icon={FaTelegramPlane}
              href="https://t.me/Myr_Grovve"
            >
              myr_grovve
            </ContactButton>
            <ContactButton icon={FaMapMarkerAlt}>
              São Paulo, Brazil
            </ContactButton>
          </div>
        </div>
      </Card>

      <Card className="mb-4 border-guinera-400 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-guinera-800 dark:text-brutal-100">
          Follow Me
        </h2>
        <div className="flex flex-col items-center pb-10">
          <div className="flex flex-col w-full px-4 mt-4 space-y-2 md:flex-row md:justify-center md:space-x-4 md:space-y-0 md:mt-6">
            <ContactButton
              icon={FaInstagram}
              href="https://www.instagram.com/gmnrart"
            >
              Instagram
            </ContactButton>
            <ContactButton icon={FaGithub} href="https://github.com/gmnr">
              GitHub
            </ContactButton>
            <ContactButton icon={FaTwitter} href="https://twitter.com/gmnrmyr">
              Twitter
            </ContactButton>
            <ContactButton
              icon={FaLinkedin}
              href="https://www.linkedin.com/in/gmnr9"
            >
              LinkedIn
            </ContactButton>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Contact;
