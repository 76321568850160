import { useEffect, useState } from "react";
import { fetchFxhashArtwork } from "../../utils/fxhash.js";

const Fxhashtest = () => {
  const [artwork, setArtwork] = useState(null);
  const artworkId = "24731"; // Replace with your actual artwork ID

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFxhashArtwork(artworkId);
        setArtwork(data);
      } catch (error) {
        console.error("Error fetching artwork:", error);
      }
    };

    fetchData();
  }, [artworkId]);

  if (!artwork) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ padding: "20px" }}>
      <h1>{artwork.title}</h1>
      <img
        src={artwork.image_url}
        alt={artwork.title}
        style={{ maxWidth: "100%" }}
      />
      <p>{artwork.description}</p>
    </div>
  );
};

export default Fxhashtest;
