import { useState } from "react";
import { Card, Button, Badge, Tooltip, Avatar } from "flowbite-react";

const Hobbies = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="max-w-2xl p-4 mx-auto bg-gray-100 rounded-lg shadow-lg dark:bg-gray-900">
      <div className="flex items-center justify-between">
        <h2
          onClick={() => setIsVisible(!isVisible)}
          className="text-3xl font-bold text-gray-900 cursor-pointer dark:text-white"
        >
          Hobbies {isVisible ? "▲" : "▼"}
        </h2>
        <Tooltip content="Toggle visibility">
          <Button
            onClick={() => setIsVisible(!isVisible)}
            size="sm"
            color="light"
          >
            {isVisible ? "Hide" : "Show"}
          </Button>
        </Tooltip>
      </div>
      {isVisible && (
        <div className="mt-4 space-y-4">
          <Card className="bg-white dark:bg-gray-800">
            <div className="flex items-center space-x-4">
              <Avatar img="/path/to/movies.jpg" rounded={true} />
              <div>
                <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Movies
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  Inception, The Matrix, Interstellar
                </p>
                <Badge color="info">Favorite</Badge>
              </div>
            </div>
          </Card>
          <Card className="bg-white dark:bg-gray-800">
            <div className="flex items-center space-x-4">
              <Avatar img="/path/to/games.jpg" rounded={true} />
              <div>
                <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                  Games
                </h5>
                <p className="font-normal text-gray-700 dark:text-gray-400">
                  The Legend of Zelda, The Witcher 3, Dark Souls
                </p>
                <Badge color="success">Top Pick</Badge>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default Hobbies;
