import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function AboutController() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Abouts 🟡
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button size="xs" onClick={() => handleNavigation("/about")}>
                M1
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/aboutv2")}>
                M2
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/#")}>
                nada
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/aboutv4")}>
                M4
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/aboutv5")}>
                M5
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/about")}>
                D1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/aboutv2")}
              >
                D2
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/#")}>
                nada
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/aboutv4")}
              >
                D4
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default AboutController;
