import { Card } from "flowbite-react";

const CurriculumAvatarBox = () => {
  return (
    <Card className="mb-4 bg-blackthorne-100 dark:bg-blackthorne-500">
      <div className="flex flex-col items-center sm:flex-row sm:items-center">
        <img
          src="/myrequal.png"
          alt="Avatar"
          className="w-16 h-16 mb-4 rounded-full sm:mb-0 sm:mr-4"
        />
        <div className="text-center sm:text-left">
          <h2 className="text-2xl font-bold text-brutal-900 dark:text-brutal-100">
            Guilherme Myr Manera
          </h2>
          <p className="text-gray-900 dark:text-gray-400">
            T-shaped UX Designer at BonusBlock
          </p>
        </div>
      </div>
    </Card>
  );
};

export default CurriculumAvatarBox;
