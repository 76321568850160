import { useState } from "react";
import { Card, Button } from "flowbite-react";
import PersonalSection from "../../components/PersonalSection";
import CommercialSection from "../../components/CommercialSection";
import AboutThisWebsite from "../../components/AboutThisWebsite";

const AboutV2 = () => {
  const [selectedCategory, setSelectedCategory] = useState("commercial");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const personalGoals = ["Goal 1", "Goal 2"];
  const commercialGoals = ["Goal A", "Goal B"];
  const commercialSkills = ["Skill X", "Skill Y"];

  return (
    <div>
      <h1>About</h1>
      <div>
        <Button onClick={() => handleCategoryChange("personal")}>
          Personal
        </Button>
        <Button onClick={() => handleCategoryChange("commercial")}>
          Commercial
        </Button>
        <Button onClick={() => handleCategoryChange("website")}>
          This Website
        </Button>
      </div>
      <div>
        {selectedCategory === "personal" && (
          <Card>
            <PersonalSection goals={personalGoals} />
          </Card>
        )}
        {selectedCategory === "commercial" && (
          <Card>
            <CommercialSection
              goals={commercialGoals}
              skills={commercialSkills}
            />
          </Card>
        )}
        {selectedCategory === "website" && (
          <Card>
            <AboutThisWebsite />
          </Card>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default AboutV2;
