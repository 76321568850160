import { useEffect } from "react";
import PropTypes from "prop-types";

function AnimationController({
  currentAnimation,
  enableAnimations,
  toggleAnimations,
}) {
  useEffect(() => {
    if (enableAnimations) {
      console.log(`Playing animation: ${currentAnimation}`);
      // Logic to play the animation
    } else {
      console.log("Animations are disabled");
      // Logic to stop the animation
    }
  }, [currentAnimation, enableAnimations]);

  return (
    <div>
      <div className="mb-3"></div> {/* Small spacing div */}
      <div className="mb-4 text-left">
        <p className="text-sm text-pendle-800 dark:text-brutal-200 ms-7">
          Current Animation: {currentAnimation}
        </p>
        <div className="flex items-center mt-4 ms-7">
          <label className="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              checked={enableAnimations}
              onChange={toggleAnimations}
              className="sr-only peer"
            />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-pendle-600 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-brutal-200"></div>
            <span className="text-sm font-medium text-primary-600 dark:text-brutal-200 ms-4">
              Animations Enabled: {enableAnimations ? "Yes" : "No"}
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

AnimationController.propTypes = {
  currentAnimation: PropTypes.string.isRequired,
  enableAnimations: PropTypes.bool.isRequired,
  toggleAnimations: PropTypes.func.isRequired,
};

export default AnimationController;
