// copy of experiment cause it works well responsivitywise

import DynamicSpline from "../../components/DynamicSpline.jsx";
import SplineRandom from "../../components/SplineRandom.jsx";

function HomeV9() {
  return (
    <div style={{ height: "1", width: "150vw" }}>
      <SplineRandom />
      <DynamicSpline />
    </div>
  );
}

export default HomeV9;
