// small edits

import { useState } from "react";
import { Button } from "flowbite-react";
import NavbarRefracted from "./NavbarRefracted.jsx";
import NavbarRefractedV2 from "./NavbarRefractedV2.jsx";
import NavbarRefractedV3 from "./NavbarRefractedV3.jsx";
import NavbarRefractedV4 from "./NavbarRefractedV4.jsx";
import NavbarRefractedV5 from "./NavbarRefractedV5.jsx";
import { NavbarRound1 } from "./NavbarRound1";

function NavbarTesterV3() {
  const navbars = [
    NavbarRefracted,
    NavbarRefractedV2,
    NavbarRefractedV3,
    NavbarRefractedV4,
    NavbarRefractedV5,
    NavbarRound1,
  ];

  const [currentNavbarIndex, setCurrentNavbarIndex] = useState(0);

  const toggleNavbarVersion = (index) => {
    setCurrentNavbarIndex(index);
  };

  const CurrentNavbar = navbars[currentNavbarIndex];

  return (
    <div>
      <div className="fixed bottom-0 left-0 z-50 flex p-1 space-x-1 bg-gray-800">
        <Button size="xs" color="light" onClick={() => toggleNavbarVersion(0)}>
          1
        </Button>
        <Button size="xs" color="light" onClick={() => toggleNavbarVersion(1)}>
          2
        </Button>
        <Button size="xs" color="light" onClick={() => toggleNavbarVersion(2)}>
          3
        </Button>
        <Button size="xs" color="light" onClick={() => toggleNavbarVersion(3)}>
          4
        </Button>
        <Button size="xs" color="light" onClick={() => toggleNavbarVersion(4)}>
          5
        </Button>
        <Button size="xs" color="light" onClick={() => toggleNavbarVersion(5)}>
          6
        </Button>
      </div>
      <div key={currentNavbarIndex}>
        <CurrentNavbar />
      </div>
    </div>
  );
}

export default NavbarTesterV3;
