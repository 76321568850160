// Portfolio Page. Displays 1) Project Thumbnails <-- not working, clickable and (editing)

// Portfolio Page. Displays 1) Project Thumbnails <-- not working, clickable and (editing)

import { useState, useEffect } from "react";
import PortfolioCategories from "../../components/PortfolioCategories.jsx";
import ProjectDetails from "../../components/ProjectDetails.jsx";
import ProjectThumbnail from "../../components/ProjectThumbnail.jsx";
import projectsData from "../../projects.json";

const PortfolioV1 = () => {
  const [selectedCategory, setSelectedCategory] = useState("UX Design");
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const displayLimit = 11;

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  const filteredProjects =
    selectedCategory === "All"
      ? projects
      : projects.filter((project) => project.category === selectedCategory);

  const handleProjectClick = (project) => {
    if (selectedProject && selectedProject.id === project.id) {
      setSelectedProject(null);
    } else {
      setSelectedProject(project);
    }
  };

  return (
    <div className="mt-32">
      <PortfolioCategories
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
      <div className="grid grid-cols-1 gap-4 sm:mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:gap-8">
        {filteredProjects.slice(0, displayLimit).map((project) => (
          <ProjectThumbnail
            key={project.id}
            project={project}
            onClick={handleProjectClick}
          />
        ))}
      </div>
      {selectedProject && (
        <ProjectDetails
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
};

export default PortfolioV1;
