import HomeText from "../../components/HomeText.jsx";
import "./Home.css";
import ThreeDScene from "../../components/ThreeDScene.jsx";

function Mhendra() {
  return (
    <div
      className="home-container"
      style={{
        paddingTop: "4px",
        backdropFilter: "blur(8px)",
        backgroundColor: "transparent",
        position: "relative",
      }}
    >
      <div style={{ width: "800px", height: "600px" }}>
        <ThreeDScene />
      </div>
      <HomeText />
    </div>
  );
}

export default Mhendra;
