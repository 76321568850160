const TermsOfService = () => (
  <div className="container p-4 m-32 mx-auto bg-guinera-100 dark:bg-blackthorne-700 text-blackthorne-900 dark:text-guinera-100">
    <h1 className="mb-4 text-2xl font-bold text-brutal-200 dark:text-brutal-200">
      Terms of Service
    </h1>
    <p className="mb-4">
      Welcome to guimanera.com.br. By accessing or using our website, you agree
      to comply with and be bound by the following terms and conditions of use,
      which together with our privacy policy govern guimanera.com.br
      relationship with you in relation to this website.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Use of the Website
    </h2>
    <p className="mb-4">
      The content of the pages of this website is for your general information
      and use only. It is subject to change without notice.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Intellectual Property
    </h2>
    <p className="mb-4">
      This website contains material which is owned by or licensed to us. This
      material includes, but is not limited to, the design, layout, look,
      appearance, and graphics. Reproduction is prohibited other than in
      accordance with the copyright notice, which forms part of these terms and
      conditions.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      User Conduct
    </h2>
    <p className="mb-4">
      You agree not to use this website in any way that may cause the website to
      be interrupted, damaged, or impaired in any way. You understand that you
      are responsible for all electronic communications and content sent from
      your computer to this website and you must use the website for lawful
      purposes only.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Limitation of Liability
    </h2>
    <p className="mb-4">
      The information on this website is provided on an as is basis. To the
      fullest extent permitted by law, this company excludes all representations
      and warranties relating to this website and its contents, including in
      relation to any inaccuracies or omissions in this website.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Governing Law
    </h2>
    <p className="mb-4">
      These terms and conditions are governed by and construed in accordance
      with the laws of Brazil and you irrevocably submit to the exclusive
      jurisdiction of the courts in that State or location.
    </p>
  </div>
);

export default TermsOfService;
