import SplineStatic from "../../splinestatic.jsx";

function HomeV4() {
  return (
    <div className="home-container">
      <SplineStatic />
    </div>
  );
}

export default HomeV4;
