// Feeds the links to the NavbarRefracted.jsx component.
// Displayed on NavbarRefractedv4.jsx
// changed navbarlinks so mobile menu stays good on light mode... still it wont have background blur cause that would mess the ddesktop but thats fine

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import NavbarDropdown from "./NavbarDropdown";

function NavbarLinks({ toggleDarkMode, darkMode }) {
  return (
    <ul className="flex flex-col items-center p-4 mt-4 font-medium bg-opacity-50 border rounded-lg bg-guinera-500 dark:bg-guinera-900 dark:bg-opacity-50 border-guinera-300 md:p-0 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-transparent md:dark:bg-transparent dark:border-guinera-900 dark:md:bg-transparent dark:md:bg-opacity-0 backdrop-blur-md md:backdrop-blur-none">
      <li className="ml-4">
        <Link
          to="/"
          className="block px-3 py-2 font-bold bg-opacity-50 rounded text-pendle-800 hover:bg-brutal-300 md:hover:bg-transparent md:border-0 md:hover:text-brutal-300 md:p-0 dark:text-pendle-100 dark:bg-opacity-50 md:dark:hover:text-pendle-200 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          Home
        </Link>
      </li>
      <li className="ml-4">
        <Link
          to="/portfolio"
          className="block px-3 py-2 font-bold bg-opacity-50 rounded text-pendle-800 hover:bg-brutal-300 md:hover:bg-transparent md:border-0 md:hover:text-brutal-300 md:p-0 dark:text-pendle-100 dark:bg-opacity-50 md:dark:hover:text-pendle-200 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          Portfolio
        </Link>
      </li>
      <li className="ml-4">
        <Link
          to="/about"
          className="block px-3 py-2 font-bold bg-opacity-50 rounded text-pendle-800 hover:bg-brutal-300 md:hover:bg-transparent md:border-0 md:hover:text-brutal-300 md:p-0 dark:text-pendle-100 dark:bg-opacity-50 md:dark:hover:text-pendle-200 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          About
        </Link>
      </li>
      <li className="ml-4">
        <Link
          to="/contact"
          className="block px-3 py-2 font-bold bg-opacity-50 rounded text-pendle-800 hover:bg-brutal-300 md:hover:bg-transparent md:border-0 md:hover:text-brutal-300 md:p-0 dark:text-pendle-100 dark:bg-opacity-50 md:dark:hover:text-pendle-200 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          Contact
        </Link>
      </li>
      <li className="ml-4">
        <Link
          to="/experiment"
          className="block px-3 py-2 font-bold bg-opacity-50 rounded text-pendle-800 hover:bg-brutal-300 md:hover:bg-transparent md:border-0 md:hover:text-brutal-300 md:p-0 dark:text-pendle-100 dark:bg-opacity-50 md:dark:hover:text-pendle-200 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          Experiment
        </Link>
      </li>
      <li className="mt-2 mb-4 ml-1 md:mb-1">
        <button
          onClick={toggleDarkMode}
          className="block px-3 py-2 bg-opacity-50 rounded text-pendle-800 hover:bg-brutal-300 md:hover:bg-transparent md:border-0 md:hover:text-brutal-300 md:p-0 dark:text-pendle-100 dark:bg-opacity-50 md:dark:hover:text-pendle-200 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        >
          {darkMode ? (
            <svg
              className="w-[18px] h-[18px] text-pendle-50 hover:text-brutal-400  dark:hover:text-brutal-200 dark:text-pendle-100"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fillRule="evenodd"
                d="M11.675 2.015a.998.998 0 0 0-.403.011C6.09 2.4 2 6.722 2 12c0 5.523 4.477 10 10 10 4.356 0 8.058-2.784 9.43-6.667a1 1 0 0 0-1.02-1.33c-.08.006-.105.005-.127.005h-.001l-.028-.002A5.227 5.227 0 0 0 20 14a8 8 0 0 1-8-8c0-.952.121-1.752.404-2.558a.996.996 0 0 0 .096-.428V3a1 1 0 0 0-.825-.985Z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              className="w-[16px] h-[16px] text-guinera-800 dark:hover:text-brutal-200  hover:text-brutal-400 dark:text-red-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="26"
              height="26"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 21a9 9 0 0 1-.5-17.986V3c-.354.966-.5 1.911-.5 3a9 9 0 0 0 9 9c.239 0 .254.018.488 0A9.004 9.004 0 0 1 12 21Z"
              />
            </svg>
          )}
        </button>
      </li>
      <NavbarDropdown />
    </ul>
  );
}

NavbarLinks.propTypes = {
  toggleDarkMode: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default NavbarLinks;
