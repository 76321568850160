// This is the text that appears on Home.jsx. It is a simple h1 tag with some styling. But in the future we will add the typewriter effect to it.
// Displayed on Home

// new cool text to variate -> Underpromess and Overdeliver

import "./HomeText.css";

function HomeText() {
  return (
    <div className="home-text-container">
      <h1 className="home-text">Outsider &amp; Hard Worker</h1>
    </div>
  );
}

export default HomeText;
