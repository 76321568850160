// src/components/ProjectThumbnailsV3Grid.jsx

import PropTypes from "prop-types";
import ProjectThumbnailV3 from "./ProjectThumbnailV3.jsx";
import styles from "./ProjectThumbnailsV3Grid.module.css";

const ProjectThumbnailsV3Grid = ({ projects, onProjectClick }) => {
  return (
    <div className={styles.grid}>
      {projects.map((project) => (
        <ProjectThumbnailV3
          key={project.id}
          project={project}
          onClick={() => onProjectClick(project)}
        />
      ))}
    </div>
  );
};

ProjectThumbnailsV3Grid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProjectClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailsV3Grid;
