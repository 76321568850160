// Displayed on ProjectThumbnailsV3Grid (Portfolio)
// Card from flowbites.
// Default image they`re using ( https://flowbite.com/docs/images/blog/image-1.jpg ) // 920x613px
// Images imported here (trough json) should keep 920x613 otherwise it can loose responsivity.

// src/components/ProjectThumbnailV3.jsx

// src/components/ProjectThumbnailV3.jsx

import PropTypes from "prop-types";

const ProjectThumbnailV3 = ({ project, onClick }) => {
  return (
    <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div onClick={() => onClick(project)}>
        <img
          className="rounded-t-lg cursor-pointer"
          src={project.SelectorThumbLight}
          alt={project.name}
        />
      </div>
      <div className="p-5">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
          {project.name}
        </h5>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {project.shortDescription}
        </p>
        <a
          href="#"
          onClick={() => onClick(project)}
          className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Read more
          <svg
            className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 10"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 5h12m0 0L9 1m4 4L9 9"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

ProjectThumbnailV3.propTypes = {
  project: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailV3;
