import PropTypes from "prop-types";
import ProjectThumbnailV5 from "./ProjectThumbnailV5.jsx";
import styles from "./ProjectThumbnailsV5Grid.module.css";

const ProjectThumbnailsV5Grid = ({ projects, onProjectClick }) => {
  return (
    <div className={styles.grid}>
      {projects.map((project) => (
        <ProjectThumbnailV5
          key={project.id}
          project={project}
          onClick={() => onProjectClick(project)}
        />
      ))}
    </div>
  );
};

ProjectThumbnailsV5Grid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProjectClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailsV5Grid;
