import PropTypes from "prop-types";

const ProjectThumbnailV5 = ({ project, onClick }) => {
  return (
    <div
      className="CombinedCards w-[306px] h-[171px] relative"
      onClick={onClick}
    >
      <div className="BackCardBlur h-[171px] left-0 top-0 absolute opacity-75 rounded-2xl border blur-[69.90px] flex-col justify-start items-start inline-flex z-0">
        <div className="TopCard self-stretch h-[131px] bg-black flex-col justify-start items-start gap-2.5 flex">
          <div className="inline-flex items-center justify-start ImageContainer">
            <div className="ThumbnailImage w-[306px] h-[171px] justify-center items-center inline-flex rounded-b-2xl">
              <img
                className="object-cover w-full h-full rounded-t-2xl rounded-b-2xl"
                src={project.SelectorThumbLight}
                alt={project.name}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="PortfolioCard h-[171px] left-0 top-0 absolute rounded-2xl border border-gray-900 flex-col justify-start items-start inline-flex z-10">
        <div className="TopCardImage self-stretch h-[131px] bg-transparent flex-col justify-start items-start gap-2.5 flex">
          <div className="inline-flex items-center justify-start ImageContainer">
            <div className="ThumbnailImage w-[306px] h-[171px] justify-center items-center inline-flex rounded-b-2xl">
              <img
                className="object-cover w-full h-full rounded-t-2xl rounded-b-2xl"
                src={project.SelectorThumbLight}
                alt={project.name}
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
        <div className="Label self-stretch h-10 px-4 py-[13px] bg-black/30 rounded-bl-2xl rounded-br-2xl backdrop-blur-[32.80px] justify-start items-center gap-2.5 inline-flex border-l border-r border-b border-gray-900">
          <div className="LabelFrame justify-center items-center gap-2.5 flex">
            <div className="BaseChainWhitelabel text-primary-100 text-xs font-normal font-['Inter'] leading-[17.64px]">
              {project.name}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectThumbnailV5.propTypes = {
  project: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailV5;
