// src/components/CommercialSection.jsx

import PropTypes from "prop-types";
import SkillDetails from "./SkillDetails";
import WorkHistory from "./WorkHistory";
import CustomerLogoGrid from "./CustomerLogoGrid";
import GoalsList from "./GoalsList";

const CommercialSection = ({ goals = [], skills = [] }) => {
  return (
    <div>
      <h2>Commercial</h2>
      <SkillDetails skills={skills} />
      <WorkHistory />
      <GoalsList goals={goals} />
      <CustomerLogoGrid />
    </div>
  );
};

CommercialSection.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.string),
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }),
  ),
};

export default CommercialSection;
