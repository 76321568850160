//foi o que a Fe mais gostou

import SplineStatic from "../../splinestatic.jsx";
import "./HomeV3.css";

function HomeV3() {
  return (
    <div className="home-container">
      <SplineStatic />
    </div>
  );
}

export default HomeV3;
