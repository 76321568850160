// src/experimento.jsx aqui, componente de experimento spline. Atualmente mao em 3D.

// Displayed inside Experiment page.

import Spline from "@splinetool/react-spline";
import "flowbite-react";

function Experimento() {
  return (
    <Spline scene="https://prod.spline.design/TyFWI-KBvnp5GclE/scene.splinecode" />
  );
}

export default Experimento;
