// src/components/AboutMe.jsx

import { Card } from "flowbite-react";
import "flowbite-react";

const AboutMe = () => {
  return (
    <div className="bg-white dark:bg-gray-800">
      <Card className="p-4">
        <h2 className="mb-4 text-2xl font-bold text-guinera-400 ">About Me</h2>
        <p className="text-guinera-700 dark:text-guinera-300">
          I am from Brazil, born in the 90s. I love playing video-games, making
          and listening to music, watching scary movies and series, hanging out
          with my girlfriend, hanging out with my family, hanging out with my
          friends, coffee, making art, drinking beer, being with myself.
        </p>
      </Card>
    </div>
  );
};

export default AboutMe;
