import { Card, Dropdown, Avatar, Button } from "flowbite-react";
import AboutMe from "./AboutMe";
import Hobbies from "./Hobbies";
import Dedication from "./Dedication";

const PersonalSection = () => {
  return (
    <Card className="w-full max-w-sm">
      <div className="flex justify-end px-4 pt-4">
        <Dropdown
          label={
            <Button color="gray" size="xs">
              <span className="sr-only">Open dropdown</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 16 3"
              >
                <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
              </svg>
            </Button>
          }
          arrowIcon={false}
          inline={true}
        >
          <Dropdown.Item> Edit </Dropdown.Item>
          <Dropdown.Item> Export Data </Dropdown.Item>
          <Dropdown.Item> Delete </Dropdown.Item>
        </Dropdown>
      </div>
      <div className="flex flex-col items-center pb-10">
        <Avatar
          img="/myrequal.png"
          rounded={false}
          size="lg"
          className="mb-5"
          alt="Guilherme Manera"
        />
        <h5 className="mb-1 text-xl font-medium text-guinera-900 dark:text-guinera-100">
          Guilherme Manera
        </h5>
        <span className="text-sm text-gray-500 dark:text-gray-400">
          T-shaped UX Designer
        </span>
        <div className="flex mt-4 md:mt-6">
          <Button className="text-guinera-900 bg-guinera-500 ms-2 hover:bg-gray-400 border-guinera-200">
            Add Friend
          </Button>
          <Button className="text-blackthorne-900 bg-pendle-500 ms-2 border-pendle-300 hover:bg-gray-400">
            Message
          </Button>
        </div>
      </div>
      <AboutMe />
      <Hobbies className="text-sm text-gray-500 dark:text-gray-400" />
      <Dedication />
    </Card>
  );
};

export default PersonalSection;
