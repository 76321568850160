// Pretty sure that this grabs the technologies from the json and displays them on a list inside the project details.

import PropTypes from "prop-types";

const TechnologyStack = ({ techTools }) => (
  <div className="mt-4">
    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
      Tech/Tools
    </h3>
    <ul className="mt-2 list-disc list-inside">
      {techTools.map((tech, index) => (
        <li key={index} className="text-gray-700 dark:text-gray-300">
          {tech}
        </li>
      ))}
    </ul>
  </div>
);

TechnologyStack.propTypes = {
  techTools: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TechnologyStack;
