import { useState, useEffect } from "react";

const phrases = [
  "OUTSIDER &",
  "HARD WORKER",
  "PROBLEM SOLVER",
  "UNDERPROMISSE",
  "& OVER",
  "DELIVER",
  "PIXEL-PERFECT &",
  "USER-CENTERED",
  "FROM CONCEPT",
  "TO CREATION",
  "FROM A COFFEE",
  "TO A WEBSITE",
  "FROM A SKETCH",
  "TO A PROTOTYPE",
  "FROM",
  "A WIREFRAME",
  "TO A PRODUCT",
  "NEVER",
  "UNDERSTIMATE",
  "THE POWER",
  "OF A",
  "GREAT DESIGN",
  "FROM BRAZIL",
  "TO THE WORLD",
  "AND ALSO",
  "TO PORTUGAL",
  "FROM A",
  "WIREFRAME",
  "TO A <DIV>",
  "FROM",
  "ROOT{}",
  "TO",
  "USER",
  "DELIGHT",
  "PIXEL, CODE,",
  "& COFFEE",
  "BREAKS",
  "GIT ADD .",
  "GIT COMMIT -M",
  "A.I STILL",
  "CAN'T MAKE 3D",
];

const TypingAnimation = () => {
  const [currentPhrase, setCurrentPhrase] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [loopNum, setLoopNum] = useState(0);
  const [typingSpeed, setTypingSpeed] = useState(150);

  useEffect(() => {
    const handleTyping = () => {
      const i = loopNum % phrases.length;
      const fullText = phrases[i];

      setCurrentPhrase(
        isDeleting
          ? fullText.substring(0, currentPhrase.length - 1)
          : fullText.substring(0, currentPhrase.length + 1),
      );

      setTypingSpeed(isDeleting ? 30 : 150);

      if (!isDeleting && currentPhrase === fullText) {
        setTimeout(() => setIsDeleting(true), 500);
      } else if (isDeleting && currentPhrase === "") {
        setIsDeleting(false);
        setLoopNum(loopNum + 1);
      }
    };

    const timer = setTimeout(handleTyping, typingSpeed);

    return () => clearTimeout(timer);
  }, [currentPhrase, isDeleting, loopNum, typingSpeed]);

  return (
    <span
      className="select-none font-regular font-aldrich"
      style={{ paddingBottom: "2px", lineHeight: "1.2" }}
    >
      {currentPhrase}
    </span>
  );
};

export default TypingAnimation;
