// (1) Currently not in use. We are using ProjectThumbnailsV2.jsx instead. (Newer version)

// (2, old) src/components/ProjectThumbnail.jsx <--- currently not in use (pretty sure)

import PropTypes from "prop-types";
import styles from "./ProjectThumbnail.module.css";

const ProjectThumbnail = ({ project, onClick }) => {
  return (
    <div className={styles.thumbnail} onClick={() => onClick(project)}>
      <img
        src={project.SelectorThumbDark}
        alt={project.name}
        className={styles.thumbnailImage}
      />
      <h3 className={styles.thumbnailTitle}>{project.name}</h3>
      <p className={styles.thumbnailDescription}>{project.shortDescription}</p>
    </div>
  );
};

ProjectThumbnail.propTypes = {
  project: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProjectThumbnail;
