import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function PortifController() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Portfolios 🟢 (refsworkonpageindexonly)
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov1")}
              >
                D1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov2")}
              >
                D2
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov3")}
              >
                D3
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov4")}
              >
                D4
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov5")}
              >
                D5
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov5")}
              >
                🔴D6
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov7")}
              >
                D7
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov8")}
              >
                D8
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfoliov9")}
              >
                D9
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/portfliowrapper")}
              >
                DW
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default PortifController;
