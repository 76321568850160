import "./HomeV8.css";

function HomeV8() {
  return (
    <div className="homev8-container">
      <div className="h-full space-y-0 ">
        <div className="flex space-x-8">
          <div className="w-full h-32 bg-red-600 home-item"></div>
          <div className="w-full h-32 bg-green-600 home-item"></div>
          <div className="w-full h-32 bg-green-600 home-item"></div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full h-32 bg-blue-600 home-item"></div>
          <div className="w-full h-32 bg-yellow-600 home-item"></div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full h-32 bg-purple-600 home-item"></div>
          <div className="w-full h-32 bg-green-600 home-item"></div>
          <div className="w-full h-32 bg-orange-600 home-item"></div>
          <div className="w-full h-32 bg-red-600 home-item"></div>
          <div className="w-full h-32 bg-green-600 home-item"></div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full h-32 bg-blue-600 home-item"></div>
          <div className="w-full h-32 bg-yellow-600 home-item"></div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full h-32 bg-purple-600 home-item"></div>
          <div className="w-full h-32 bg-orange-600 home-item"></div>
          <div className="w-full h-32 bg-red-600 home-item"></div>
          <div className="w-full h-32 bg-green-600 home-item"></div>
        </div>
        <div className="flex flex-col space-y-4">
          <div className="w-full h-32 bg-blue-600 home-item"></div>
          <div className="w-full h-32 bg-yellow-600 home-item"></div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full h-32 bg-purple-600 home-item"></div>
          <div className="w-full h-32 bg-orange-600 home-item"></div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full h-32 bg-purple-600 home-item"></div>
          <div className="w-full h-32 bg-orange-600 home-item"></div>
        </div>
        <div className="flex space-x-4">
          <div className="w-full h-32 bg-purple-600 home-item"></div>
          <div className="w-full h-32 bg-orange-600 home-item"></div>
        </div>
      </div>
    </div>
  );
}

export default HomeV8;
