// Display all the project details when you click on a project card in the Portfolio.
// Displayed inside portfolio

// #TODO (1) in the future add ifelse logic... on the projects json we woull add a fullprojectpage: projectdtails could pull from. If its not avaliable, continue with the same logic.
// (1) That would work well in cases where we want a fully customized page for a specific project. Example, there's a project that I want to simply display text and gallery and not pull all that info from json, so that logic would work and pull a predefinied page for that specific project instead of rendering the projectdescription, techtools etc it would only render this page. It's for times where we want a more customized approach for a certain project. It would still identify it by id of course and keep the same logic for that.

import PropTypes from "prop-types";
import DescriptionThumbnail from "./DescriptionThumbnail";
import ProjectDescription from "./ProjectDescription";
import TechnologyStack from "./TechnologyStack";

const ProjectDetails = ({ project }) => {
  if (!project) return null;

  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-sm dark:border-gray-700 dark:bg-gray-800">
      <h2 className="text-xl font-bold text-gray-900 dark:text-white">
        {project.name}
      </h2>

      <div className="flex">
        <DescriptionThumbnail
          DescriptionThumbLight={project.DescriptionThumbLight}
          DescriptionThumbDark={project.DescriptionThumbDark}
          altText={project.name}
        />
        <ProjectDescription
          description={project.description}
          projectId={project.id}
        />
      </div>

      <TechnologyStack techTools={project.techTools} />

      <div className="mt-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
          Tools
        </h3>
        <ul className="mt-2 list-disc list-inside">
          {project.techTools.map((tool, index) => (
            <li key={index} className="text-gray-700 dark:text-gray-300">
              {tool}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

ProjectDetails.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    DescriptionThumbLight: PropTypes.string.isRequired,
    DescriptionThumbDark: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    techTools: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

export default ProjectDetails;
