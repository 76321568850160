// Default
import { createContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import "./App.css";
import PropTypes from "prop-types";
import FooterV2 from "./components/FooterV2.jsx";

// Tools
import DevDropdown from "./components/devmenu/DevDropdown.jsx";
import SimpleBar from "simplebar-react";
import NavbarTester from "./components/NavbarTester.jsx";
import NavbarTesterV3 from "./components/NavbarTesterV3.jsx";
import DevCaller from "./components/DevCaller";
import DevLayout from "./components/tools/DevLayout.jsx";

// Spline Animations
import SquareAnim from "./components/squaresanim.jsx";
import Experimento from "./components/experimento.jsx";

// Homes
import Home from "./pages/dev/Home.jsx";
import HomeV2 from "./pages/dev/HomeV2.jsx";
import HomeV3 from "./pages/dev/HomeV3.jsx";
import HomeV4 from "./pages/dev/HomeV4.jsx";
import HomeV5 from "./pages/dev/HomeV5.jsx";
import HomeV6 from "./pages/dev/HomeV6.jsx";
import HomeV7 from "./pages/dev/HomeV7.jsx";
import HomeV8 from "./pages/dev/HomeV8.jsx";
import HomeV9 from "./pages/dev/HomeV9.jsx";
import HomeV10 from "./pages/HomeV10.jsx";
import HomeV11 from "./pages/dev/HomeV11.jsx";

// Abouts
import About from "./pages/About.jsx";
import AboutV2 from "./pages/dev/AboutV2.jsx";

// Portfolios
import Portfolio from "./pages/Portfolio.jsx";
import PortfolioV1 from "./pages/dev/PortfolioV1.jsx";
import PortfolioV2 from "./pages/dev/PortfolioV2.jsx";
import PortfolioV3 from "./pages/dev/PortfolioV3.jsx";
import PortfolioV4 from "./pages/dev/PortfolioV4.jsx";
import PortfolioV5 from "./pages/dev/PortfolioV5.jsx";
import PortfolioV7 from "./pages/dev/PortfolioV7.jsx";
import PortfolioV8 from "./pages/dev/PortfolioV8.jsx";
import PortfolioWrapper from "./pages/dev/PortfolioWrapper.jsx";

// Main Pages
import Contact from "./pages/Contact.jsx";
import ContactDev from "./pages/dev/ContactDev.jsx";
import Experiment from "./pages/Experiment.jsx";
import Soon from "./pages/Soon.jsx";
import PrivacyPolicy from "./pages/PrivacyPolicy.jsx";
import TermsOfService from "./pages/TermsOfService.jsx";

// PortfolioTools
import ProjectDetails from "./components/ProjectDetails.jsx";
import ProjectDetailsV4 from "./components/ProjectDetailsV4.jsx";
import ProjectDescriptionV2 from "./components/ProjectDescriptionV2.jsx";
import PortfolioCategories from "./components/PortfolioCategories.jsx";
import DynamicProjectComponent from "./components/figma/DynamicProjectComponent.jsx";

// Navbars
import NavbarDropdown from "./components/NavbarDropdown.jsx";
import { NavbarRound1 } from "./components/NavbarRound1";

//NavbarRefracted (use v4, v4 new in test)
import NavbarRefracted from "./components/NavbarRefracted";
import NavbarRefractedV2 from "./components/NavbarRefractedV2";
import NavbarRefractedV3 from "./components/NavbarRefractedV3";
import NavbarRefractedV4 from "./components/NavbarRefractedV4";
import NavbarRefractedV5 from "./components/NavbarRefractedV5";
import NavbarRefractedV4new from "./components/NavbarRefractedV4new";
import NavbarRefractedV4new2 from "./components/NavbarRefractedV4new2";

//Test Pages(dev)
import Fxhashtest from "./pages/dev/Fxhashtest.jsx";
import Mhendra from "./pages/dev/Mhendra.jsx";
import MetropolisTests from "./pages/dev/MetropolisTests.jsx";
import FigmaTest from "./pages/dev/FigmaTest";
import ComingSoonPage from "./pages/dev/ComingSoonPage.jsx";

//Curriculum
import Curriculum from "./pages/Curriculum.jsx";
import CurriculumRefracted from "./pages/dev/CurriculumRefracted.jsx";
import CurriculumSkillsBox from "./components/CurriculumSkillsBox.jsx";
import CurriculumLanguagesBox from "./components/CurriculumLanguagesBox.jsx";
import CurriculumSocialBox from "./components/CurriculumSocialBox.jsx";
import CurriculumExperienceBox from "./components/CurriculumExperienceBox.jsx";
import CurriculumAvatarBox from "./components/CurriculumAvatarBox.jsx";
import CurriculumLatestProjectsBox from "./components/CurriculumLatestProjectsBox.jsx";

export const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

SidebarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function App({ children }) {
  return (
    <SidebarProvider>
      <SimpleBar style={{ maxHeight: "100vh" }}>
        <div className="bg-blackthorne-100 app-container dark:bg-blackthorne-700">
          <NavbarRefractedV4 />
          <DevCaller />
          <div className="content">
            <Routes>
              <Route path="/" element={<HomeV10 />} />
              <Route path="/dev/home" element={<Home />} />
              <Route path="/dev/homev2" element={<HomeV2 />} />
              <Route path="/dev/homev3" element={<HomeV3 />} />
              <Route path="/dev/homev4" element={<HomeV4 />} />
              <Route path="/dev/homev5" element={<HomeV5 />} />
              <Route path="/dev/homev6" element={<HomeV6 />} />
              <Route path="/dev/homev7" element={<HomeV7 />} />
              <Route path="/dev/homev8" element={<HomeV8 />} />
              <Route path="/dev/homev9" element={<HomeV9 />} />
              <Route path="/dev/homev11" element={<HomeV11 />} />
              <Route path="/components/devlayout" element={<DevLayout />} />
              <Route path="/about" element={<About />} />
              <Route path="/dev/aboutv2" element={<AboutV2 />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/dev/contactdev" element={<ContactDev />} />
              <Route path="/experiment" element={<Experiment />} />
              <Route path="/curriculum" element={<Curriculum />} />
              <Route
                path="/dev/curriculumrefracted"
                element={<CurriculumRefracted />}
              />
              <Route
                path="/dev/curriculumavatarbox"
                element={<CurriculumAvatarBox />}
              />
              <Route
                path="/dev/curriculumsocialbox"
                element={<CurriculumSocialBox />}
              />
              <Route
                path="/dev/curriculumexperiencebox"
                element={<CurriculumExperienceBox />}
              />
              <Route
                path="/dev/curriculumlatestprojectsbox"
                element={<CurriculumLatestProjectsBox />}
              />
              <Route
                path="/dev/curriculumlanguagesbox"
                element={<CurriculumLanguagesBox />}
              />
              <Route
                path="/dev/curriculumskillsbox"
                element={<CurriculumSkillsBox />}
              />
              <Route path="/experimento" element={<Experimento />} />
              <Route path="/square-anim" element={<SquareAnim />} />
              <Route path="/project-details" element={<ProjectDetails />} />
              <Route path="/projectdetails" element={<ProjectDetails />} />
              <Route path="/projectdetailsv4" element={<ProjectDetailsV4 />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/dev/portfoliov1" element={<PortfolioV1 />} />
              <Route path="/dev/portfoliov2" element={<PortfolioV2 />} />
              <Route path="/dev/portfoliov3" element={<PortfolioV3 />} />
              <Route path="/dev/portfoliov4" element={<PortfolioV4 />} />
              <Route path="/dev/portfoliov5" element={<PortfolioV5 />} />
              <Route path="/dev/portfoliov7" element={<PortfolioV7 />} />
              <Route path="/dev/portfoliov8" element={<PortfolioV8 />} />
              <Route
                path="/dev/portfoliowrapper"
                element={<PortfolioWrapper />}
              />
              <Route path="/soon" element={<Soon />} />
              <Route path="/dev/comingsoonpage" element={<ComingSoonPage />} />
              <Route path="/devdropdown" element={<DevDropdown />} />
              <Route path="/navbartester" element={<NavbarTester />} />
              <Route path="/navbartesterv3" element={<NavbarTesterV3 />} />
              <Route path="/navbarround1" element={<NavbarRound1 />} />
              <Route path="/navbar-tester" element={<NavbarTester />} />
              <Route path="/navbarrefracted" element={<NavbarRefracted />} />
              <Route
                path="/navbarrefractedv4new"
                element={<NavbarRefractedV4new />}
              />
              <Route
                path="/navbarrefractedv4new2"
                element={<NavbarRefractedV4new2 />}
              />
              <Route path="/dev/figmatest" element={<FigmaTest />} />
              <Route path="/dev/fxhashtest" element={<Fxhashtest />} />
              <Route
                path="/navbarrefractedv2"
                element={<NavbarRefractedV2 />}
              />
              <Route
                path="/navbarrefractedv3"
                element={<NavbarRefractedV3 />}
              />
              <Route
                path="/navbarrefractedv4"
                element={<NavbarRefractedV4 />}
              />
              <Route
                path="/navbarrefractedv5"
                element={<NavbarRefractedV5 />}
              />
              <Route path="/dev/mhendra" element={<Mhendra />} />
              <Route
                path="/projectdescriptionv2"
                element={<ProjectDescriptionV2 />}
              />
              <Route path="/metropolistests" element={<MetropolisTests />} />
              <Route
                path="/dynamic-project"
                element={<DynamicProjectComponent />}
              />
              <Route path="/navbar-dropdown" element={<NavbarDropdown />} />
              <Route path="/navbardropdown" element={<NavbarDropdown />} />
              <Route
                path="/portfoliocategories"
                element={<PortfolioCategories />}
              />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
            </Routes>
            {children}
          </div>
          <footer>
            <FooterV2 />
          </footer>
        </div>
      </SimpleBar>
    </SidebarProvider>
  );
}

App.propTypes = {
  children: PropTypes.node,
};

export default App;
