import { useState, useEffect } from "react";
import Unlocked from "./Unlocked";
import { TextInput, Button } from "flowbite-react";
import PropTypes from "prop-types";

function DevModal({ show, onClose }) {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [password, setPassword] = useState("");
  const [showPasswordInput, setShowPasswordInput] = useState(true);

  const handleUnlock = () => {
    if (password === "123" || password === "fezinha123") {
      setIsUnlocked(true);
      setShowPasswordInput(false);
    } else {
      alert("Incorrect password");
    }
  };

  const handleBypass = () => {
    setIsUnlocked(true);
    setShowPasswordInput(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "i" || e.key === "Escape") {
        onClose();
      } else if (e.ctrlKey && e.key === "b") {
        handleBypass();
      } else if (e.ctrlKey && e.key === "x") {
        setIsUnlocked(true);
        setShowPasswordInput(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClose]);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [show]);

  if (!show) return null;

  const handleOutsideClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-end bg-black bg-opacity-50 modal-overlay"
      onClick={handleOutsideClick}
    >
      <div
        className="w-1/3 h-full p-6 overflow-auto bg-opacity-50 rounded-l-lg shadow-lg pointer-events-auto bg-guinera-900 backdrop-blur-lg"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex items-center justify-between mb-4 text-guinera-200">
          <h2 className="text-xl font-semibold text-guinera-200">
            C:\ Myr \ the_Matrix.exe
          </h2>
          <button
            onClick={onClose}
            className="text-guinera-200 hover:text-guinera-100"
          >
            &times;
          </button>
        </div>
        {/* unlocker start */}
        {showPasswordInput && (
          <div className="mt-4 text-guinera-200">
            <TextInput
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Choose your destiny"
              className="password-input"
            />
            <div className="mt-2">
              <Button onClick={handleUnlock} className="unlock-button">
                Unlock
              </Button>
            </div>
            <button onClick={handleBypass} className="hidden">
              Bypass
            </button>
          </div>
        )}
        {/* unlocker end */}
        {/* locked div start */}
        {!isUnlocked && (
          <div className="mt-4 text-guinera-200">
            <p>Have you ever experienced the 💊Matrix?</p>
          </div>
        )}
        {/* locked div end */}
        {/* unlocked div start */}
        {isUnlocked && (
          <div className="mt-4 text-guinera-200">
            <p>Welcome to the Matrix!</p>
            <Unlocked />
          </div>
        )}
        {/* unlocked div end */}
      </div>
    </div>
  );
}

DevModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DevModal;
