import "./HomeText.css";

function HomeTextV2() {
  return (
    <div className="home-text-container">
      <h1 className="home-text text-guinera-800 dark:text-pendle-600">
        Outsider <br></br> &amp; Hard <br></br>Worker
      </h1>
    </div>
  );
}

export default HomeTextV2;
