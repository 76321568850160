import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function ExtrasController() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Extra Pages 🟢
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/contactdev")}
              >
                contactdev
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/mhendra")}
              >
                mhendra
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/curriculumrefracted")}
              >
                curriculumrefracted
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/curriculum")}>
                curriculum
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/privacy-policy")}
              >
                privacypolicy
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/terms-of-service")}
              >
                termsofservice
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/extra7")}>
                7🔴
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/extra8")}>
                8🔴
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default ExtrasController;
