/// good wip

"use client";

import PropTypes from "prop-types";
import Flag from "react-flagkit";
import { Card, Avatar, Accordion, Button } from "flowbite-react";
import {
  FaEnvelope,
  FaMapMarkerAlt,
  FaGlobe,
  FaInstagram,
  FaGithub,
  FaTwitter,
  FaLinkedin,
  FaTelegramPlane,
  FaReact,
  FaHtml5,
  FaCss3Alt,
  FaJs,
  FaGit,
} from "react-icons/fa";
import { SiTailwindcss, SiNextdotjs, SiVite } from "react-icons/si";

const ContactButton = ({ icon: Icon, children, href }) => (
  <Button
    as="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center text-guinera-800 border-guinera-400 dark:text-guinera-500 dark:border-guinera-900 bg-guinera-400 dark:bg-blackthorne-600"
  >
    <Icon className="w-5 h-5 mr-2" /> {children}
  </Button>
);

ContactButton.propTypes = {
  icon: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
};

const About = () => {
  return (
    <div className="container p-4 m-32 mx-auto overflow-hidden">
      <Card className="mb-4 border-guinera-500 bg-blackthorne-100 dark:bg-blackthorne-500">
        <div className="flex flex-col items-center pb-10">
          <Avatar
            img="/myrequal.png"
            rounded={true}
            size="md"
            className="mb-5"
            alt="Guilherme Manera"
          />
          <h5 className="mb-1 text-xl font-medium text-brutal-900 dark:text-brutal-100">
            Guilherme Manera
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            T-shaped UX Designer
          </span>
          <div className="flex flex-col w-full px-4 mt-4 space-y-2 md:flex-row md:justify-center md:space-x-4 md:space-y-0 md:mt-6">
            <ContactButton icon={FaEnvelope} href="mailto:#">
              Pls dont spam me
            </ContactButton>
            <ContactButton icon={FaGlobe} href="https://guimanera.com.br">
              https://guimanera.com.br
            </ContactButton>
          </div>
          <div className="flex flex-col w-full px-4 mt-4 space-y-2 md:flex-row md:justify-center md:space-x-4 md:space-y-0 md:mt-6">
            <ContactButton
              icon={FaTelegramPlane}
              href="https://t.me/Myr_Grovve"
            >
              myr_grovve
            </ContactButton>
            <ContactButton icon={FaMapMarkerAlt}>
              São Paulo, Brazil
            </ContactButton>
          </div>
        </div>
      </Card>

      <Card className="mb-4 border-guinera-500 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-left text-brutal-900 dark:text-brutal-100">
          Socials
        </h2>
        <div className="flex flex-col space-y-2 md:flex-row md:space-x-4 md:space-y-0">
          <ContactButton
            icon={FaInstagram}
            href="https://www.instagram.com/gmnrart"
          >
            @gmnrart
          </ContactButton>
          <ContactButton icon={FaGithub} href="https://github.com/gmnrmyr">
            https://guimanera.com.br
          </ContactButton>
          <ContactButton icon={FaTwitter} href="https://twitter.com/gmnrmyr">
            @gmnrmyr
          </ContactButton>
          <ContactButton
            icon={FaLinkedin}
            href="https://www.linkedin.com/in/gmnr9"
          >
            gmnr9
          </ContactButton>
        </div>
      </Card>

      <Card className="mb-4 rounded-lg dark:border-guinera-800 border-guinera-500 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-left border-guinera-500 text-brutal-900 dark:text-brutal-100">
          Experience
        </h2>
        <Accordion alwaysOpen>
          {[
            {
              title: "BonusBlock",
              role: "Senior Product Designer",
              period: "May 2024 - Present",
              description:
                "Lead UX design initiatives, focusing on user experience improvements using design systems and front-end integration.",
              skills:
                "User Experience (UX), Tailwind CSS, Design Systems, iOS, Graphic Design, UI/UX, Creative Problem-Solving, Digital Strategy.",
              logo: "/bblogo.png",
            },
            {
              title: "Eclipse Fi",
              role: "Graphic Designer",
              period: "Sept 3 - Feb 2024",
              description:
                "Crafted branding strategies and managed 3D animation projects.",
              logo: "/eclipselogo.png",
            },
            {
              title: "Gameloft",
              role: "Product Designer",
              period: "Nov 2021 - January 2024",
              description: "Led motion design and 3D rendering projects.",
              skills:
                "Motion Design, 3D Rendering, Substance Painter, Corporate Branding, Houdini, ZBrush, Cinema 4D, Figma.",
              logo: "/gameloftlogo.png",
            },
            {
              title: "GraviDAO",
              role: "Art Director",
              period: "Sep 2010 - Jul 2013",
              description:
                "Created graphics for development, branding, and advertising teams, enhancing user experience ads placement. Collaborated with development team for seamless integration with gameplay. Designed promotional materials for target audience.",
              logo: "/gravidaologo.png",
            },
          ].map((job, index) => (
            <Accordion.Panel key={index} className="mb-2 rounded-none">
              <Accordion.Title className="flex items-center border-guinera-500 text-brutal-900 dark:text-brutal-100 bg-blackthorne-100 dark:bg-blackthorne-600 hover:bg-brutal-100 dark:hover:bg-guinera-900">
                <img src={job.logo} alt={job.title} className="w-6 h-6 mr-2" />
                {job.title}
              </Accordion.Title>
              <Accordion.Content className="text-left text-gray-900 bg-transparent border-guinera-500 dark:text-gray-400 dark:bg-transparent backdrop-blur">
                <p className="font-semibold">{job.role}</p>
                <p className="italic">{job.period}</p>
                <p>{job.description}</p>
                {job.skills && (
                  <p className="font-medium">Key skills: {job.skills}</p>
                )}
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      </Card>

      <Card className="mb-4 border-guinera-500 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-left text-brutal-900 dark:text-brutal-100">
          Latest Projects
        </h2>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="p-4 bg-blackthorne-100 dark:bg-blackthorne-500">
            <img
              src="/thumbnail7.png"
              alt="Axelar Whitelabel"
              className="object-cover w-full h-32 mb-4 rounded-lg thumbnail"
            />
            <h5 className="text-xl font-bold text-brutal-900 dark:text-brutal-100">
              Axelar Whitelabel
            </h5>
            <p className="text-gray-900 dark:text-gray-400">
              Engagement platform designed exclusively for Axelar, a leading
              name in the web3 industry.
            </p>
          </div>
          <div className="p-4 bg-blackthorne-100 dark:bg-blackthorne-500">
            <img
              src="/thumbnail1.png"
              alt="Base Engagement Platform"
              className="object-cover w-full h-32 mb-4 rounded-lg thumbnail"
            />
            <h5 className="text-xl font-bold text-brutal-900 dark:text-brutal-100">
              Base Engagement Platform
            </h5>
            <p className="text-gray-900 dark:text-gray-400">
              Tailored for Base blockchain in collaboration with BonusBlock.
            </p>
          </div>
        </div>
      </Card>

      <Card className="mb-4 bg-blackthorne-100 border-guinera-500 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-left text-brutal-900 dark:text-brutal-100">
          Education
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <Card className="flex items-center p-4 dark:text-guinera-100 border-guinera-500 bg-blackthorne-100 dark:bg-blackthorne-500">
            <img
              src="/anhembilogo.png"
              alt="Anhembi Morumbi"
              className="w-6 h-6 mr-4 lg:mx-auto"
            />
            <div className="text-left lg:text-center">
              <p>Anhembi Morumbi - Digital Design Bachelor, Dec 2015</p>
            </div>
          </Card>
          <Card className="flex items-center p-4 dark:text-guinera-100 bg-blackthorne-100 border-guinera-500 dark:bg-blackthorne-500">
            <img
              src="/courseralogo.png"
              alt="Coursera"
              className="w-6 h-6 mr-4 lg:mx-auto"
            />
            <div className="text-left lg:text-center">
              <p>Coursera - UX Design certificate, Aug 2024</p>
            </div>
          </Card>
        </div>
      </Card>

      <Card className="mb-4 bg-blackthorne-100 border-guinera-500 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-left text-brutal-900 dark:text-brutal-100">
          Languages
        </h2>
        <ul className="text-left text-gray-900 dark:text-gray-400">
          <li className="smll">
            <Flag country="BR" className="inline-block w-5 h-5 mr-2" />{" "}
            Portuguese - Native
          </li>
          <li className="smll">
            <Flag country="US" className="inline-block w-5 h-5 mr-2" /> English
            - Professional working
          </li>
          <li className="smll">
            <Flag country="ES" className="inline-block w-5 h-5 mr-2" /> Spanish
            - Elementary
          </li>
        </ul>
      </Card>

      <Card className="mb-4 text-left bg-blackthorne-100 border-guinera-500 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
          Skills
        </h2>
        <div className="grid grid-cols-2 gap-4 text-gray-900 dark:text-gray-400">
          {[
            {
              category: "Design",
              skills: [
                "User Experience",
                "Design System",
                "Design Tokens",
                "Web Design",
                "Mobile Design",
                "Wireframing",
                "Prototyping",
                "Testing",
              ],
            },
            {
              category: "Development",
              skills: [
                { name: "React JS", icon: FaReact },
                { name: "TailwindCSS", icon: SiTailwindcss },
                { name: "Vite", icon: SiVite },
                { name: "NextJS", icon: SiNextdotjs },
                { name: "HTML", icon: FaHtml5 },
                { name: "CSS", icon: FaCss3Alt },
                { name: "JS", icon: FaJs },
                { name: "Git", icon: FaGit },
              ],
            },
            {
              category: "Motion Graphics",
              skills: ["Graphic Design", "3D Rendering", "WebGL", "Texturing"],
            },
          ].map((skillSet, index) => (
            <div key={index}>
              <h3 className="font-bold">{skillSet.category}</h3>
              <ul>
                {skillSet.skills.map((skill, idx) => (
                  <li key={idx} className="flex items-center">
                    {skill.icon && <skill.icon className="w-5 h-5 mr-2" />}
                    {skill.name || skill}
                  </li>
                ))}
              </ul>
              <hr className="w-48 h-1 mx-auto my-4 border-0 rounded bg-inherit md:my-10 dark:bg-inherit" />
            </div>
          ))}
        </div>
      </Card>
    </div>
  );
};

export default About;
