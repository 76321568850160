//Copia do #, que a Fe mais gostou, (v3) vamos tentar concertar

import SplineStatic from "../../splinestatic.jsx";
import "./HomeV11.css";

function HomeV11() {
  return (
    <div className>
      <SplineStatic />
    </div>
  );
}

export default HomeV11;
