// src/pages/HomeV10.jsx
// starting to work

import "./HomeV10.css";
import SplineRandom from "../components/SplineRandom.jsx";
import TypewriterAnimation from "../components/TypewriterAnimation.jsx";

function HomeV10() {
  return (
    <div className="homev10-container sm:h-[190vw] sm:w-[150vw] 2xl:h[500px] relative">
      <div className="relative z-10 w-full h-full">
        <SplineRandom />
      </div>
      <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
        <TypewriterAnimation />
      </div>
    </div>
  );
}

export default HomeV10;
