// src/components/ComingSoonPage.jsx
// currently, the main for coming soon projects

import { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, Button } from "flowbite-react";

const ComingSoonPage = ({ project }) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll("[data-tooltip-target]");
    tooltips.forEach((tooltip) => {
      const targetId = tooltip.getAttribute("data-tooltip-target");
      const target = document.getElementById(targetId);
      if (target) {
        tooltip.addEventListener("mouseenter", () => {
          target.classList.remove("invisible", "opacity-0");
          target.classList.add("visible", "opacity-100");
        });
        tooltip.addEventListener("mouseleave", () => {
          target.classList.remove("visible", "opacity-100");
          target.classList.add("invisible", "opacity-0");
        });
      }
    });
  }, []);

  return (
    <div className="antialiased font-clash-grotesk">
      <div className="px-4 py-8 mx-auto max-w-screen-2xl lg:px-6 sm:py-16 lg:py-16">
        <Card className="space-y-8 lg:space-y-12">
          <div className="space-y-4">
            <h3 className="text-3xl font-bold leading-tight text-brutal-300 sm:text-4xl dark:text-brutal-100">
              {project.name}
            </h3>
          </div>
          <div className="space-y-6">
            <p className="text-base font-normal text-gray-500 sm:text-lg dark:text-gray-400">
              This project is coming soon!
            </p>
            <p className="text-base font-normal text-gray-500 sm:text-lg dark:text-gray-400">
              Please come back later.
            </p>
          </div>
          <Button href="/" color="primary">
            Go Back Home
          </Button>
        </Card>
      </div>
    </div>
  );
};

ComingSoonPage.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default ComingSoonPage;
