import { useState, useEffect } from "react";
import DevModal from "./DevModal";

function DevCaller() {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    console.log("Universal button clicked");
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "i") {
        setShowModal(true);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <button
        onClick={handleClick}
        className="fixed z-50 flex items-center justify-center w-4 h-4 text-white transition-colors duration-300 rounded-full opacity-5 bg-guinera-500 top-4 right-4 hover:bg-guinera-600 dark:bg-gray-800 dark:hover:bg-gray-700"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-3 h-3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 4v16m8-8H4"
          />
        </svg>
      </button>
      <DevModal show={showModal} onClose={handleClose} initialUnlocked={true} />
    </>
  );
}

export default DevCaller;
