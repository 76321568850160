import { useNavigate } from "react-router-dom";

function SquaredButtons4Controller3() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="p-4">
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <button
            className="w-full py-4 text-white bg-red-600 rounded-none hover:bg-red-700"
            onClick={() => handleNavigation("#")}
          >
            XS
          </button>
          <button
            className="w-full py-4 text-white bg-green-600 rounded-none hover:bg-green-700"
            onClick={() => handleNavigation("#")}
          >
            S
          </button>
        </div>
        <div className="flex flex-col space-y-4">
          <button
            className="w-full py-4 text-white bg-blue-600 rounded-none hover:bg-blue-700"
            onClick={() => handleNavigation("#")}
          >
            M
          </button>
          <button
            className="w-full py-4 text-white bg-yellow-600 rounded-none hover:bg-yellow-700"
            onClick={() => handleNavigation("#")}
          >
            L
          </button>
        </div>
        <div className="flex space-x-4">
          <button
            className="w-full py-4 text-white bg-purple-600 rounded-none hover:bg-purple-700"
            onClick={() => handleNavigation("#")}
          >
            XL
          </button>
          <button
            className="w-full py-4 text-white bg-orange-600 rounded-none hover:bg-orange-700"
            onClick={() => handleNavigation("#")}
          >
            XXL
          </button>
        </div>
      </div>
    </div>
  );
}

export default SquaredButtons4Controller3;
