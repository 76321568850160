// src/components/figma/FigmaEmbed.jsx
import PropTypes from "prop-types";

const FigmaEmbed = ({ embedUrl, width, height }) => {
  if (!embedUrl) {
    return <div>Embed URL is not provided</div>;
  }

  return (
    <div
      className="figma-embed-container"
      style={{ width, height, margin: "auto" }}
    >
      <iframe
        style={{ border: "none", width: "100%", height: "100%" }}
        src={embedUrl}
        allowFullScreen
      ></iframe>
    </div>
  );
};

FigmaEmbed.propTypes = {
  embedUrl: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

FigmaEmbed.defaultProps = {
  width: "800px",
  height: "450px",
};

export default FigmaEmbed;
