import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "flowbite-react";
import NavbarLogo from "./NavbarLogo";
import NavbarCollapse from "./NavbarCollapse";
import styles from "./NavbarRefractedV4.module.css";

function NavbarRefractedV4() {
  const [darkMode, setDarkMode] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="relative z-50 w-full">
      {/* Background div */}
      <div className="fixed top-0 w-full px-4 transform -translate-x-1/2 bg-opacity-50 sm:px-12 sm:m-24 left-1/2 dark:bg-opacity-50 backdrop-blur-md"></div>
      {/* Content div */}
      <Navbar
        fluid
        rounded
        className={`${styles.navbar} mt-4 fixed top-0 left-1/2 transform -translate-x-1/2 z-50 w-full bg-guinera-500 bg-opacity-50 dark:bg-guinera-900 dark:bg-opacity-50 backdrop-blur-md rounded-2xl border border-guinera-400 dark:border-guinera-600 dark:border-opacity-20`}
      >
        <div className="flex items-center justify-between w-full p-4 m-4">
          <Navbar.Brand as={Link} to="/">
            <NavbarLogo darkMode={darkMode} />
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={toggleMobileMenu}
            className="m-2 bg-transparent border-none outline-none hover:bg-transparent focus:bg-transparent"
          />
          <NavbarCollapse
            isMobileMenuOpen={isMobileMenuOpen}
            toggleDarkMode={toggleDarkMode}
            darkMode={darkMode}
          />
        </div>
      </Navbar>
    </div>
  );
}

export default NavbarRefractedV4;
