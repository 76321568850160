//currently the best

import SplineStatic from "../../splinestatic.jsx";
import HomeTextV2 from "../../components/HomeTextV2.jsx";
import "./HomeV2.css"; // Import the CSS file
import DynamicSpline from "../../components/DynamicSpline.jsx";
import SplineRandom from "../../components/SplineRandom.jsx";

function HomeV2() {
  return (
    <div className="overflow-hidden">
      <div className="relative pt-1 bg-transparent backdrop-blur-md">
        <div className="w-full h-full overflow-hidden md:h-full">
          <SplineStatic />
          <DynamicSpline />
          <SplineRandom />
        </div>
        <div className="overflow-hidden non-selectable">
          <HomeTextV2 />
        </div>
      </div>
    </div>
  );
}

export default HomeV2;
