// new best

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar } from "flowbite-react";
import NavbarLogo from "./NavbarLogo";
import NavbarLinks from "./NavbarLinks";
import styles from "./navbarrefracted.module.css";

function NavbarRefractedV3() {
  const [darkMode, setDarkMode] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <Navbar
      fluid
      rounded
      className={`${styles.navbar} fixed top-0 left-0 z-50 w-full bg-gray-500 bg-opacity-50 border-gray-200 backdrop-blur-md dark:bg-guinera-900 dark:bg-opacity-50 dark:backdrop-blur-md`}
    >
      <Navbar.Brand as={Link} to="/">
        <NavbarLogo darkMode={darkMode} />
      </Navbar.Brand>
      <Navbar.Toggle onClick={toggleMobileMenu} />
      <Navbar.Collapse
        className={`${isMobileMenuOpen ? "block" : "hidden"} w-full md:block md:w-auto`}
      >
        <NavbarLinks toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarRefractedV3;
