import { useState } from "react";
import { Card, Button } from "flowbite-react";

const Dedication = () => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="max-w-sm mx-auto">
      <h2
        onClick={() => setIsVisible(!isVisible)}
        className="text-2xl font-bold cursor-pointer"
      >
        Dedication {isVisible ? "▲" : "▼"}
      </h2>
      {isVisible && (
        <div className="mt-4 space-y-4">
          <Card>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              All my work is dedicated to those who have supported me throughout
              my journey. I am grateful for my family, friends, and mentors who
              have been there for me every step of the way. Here are some to
              name a few:
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Fernanda Melo
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              My girlfriend
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              My parents
            </h5>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Claudio Rocha
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              My mentor
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Rafael Xavier
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              My partner in crime
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Ilo
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              My good friend
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Josias Leal
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              My good friend
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Liam
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              Dude who told me about saturated backgrounds on Figma Tokens
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Bruno Calil
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              The bro!
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Fernando
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              A massive brother
            </p>
          </Card>
          <Card>
            <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
              Lucas Barra
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              BadassBro
            </p>
          </Card>
        </div>
      )}
      <Button onClick={() => setIsVisible(!isVisible)} className="mt-4">
        {isVisible ? "Hide" : "Show"} Dedications
      </Button>
    </div>
  );
};

export default Dedication;
