import { useState } from "react";
import PropTypes from "prop-types"; // Add this import
import { Sidebar, Dropdown } from "flowbite-react";

function DevDropdown() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="relative">
      <button onClick={toggleSidebar} className="p-2 bg-gray-200 rounded">
        Toggle Sidebar
      </button>
      {isSidebarOpen && (
        <div className="fixed bottom-0 left-0 z-50 w-64 inset-y-12">
          <Sidebar open={isSidebarOpen}>
            <Sidebar.Items>
              <Sidebar.ItemGroup>
                <SidebarItem
                  label="Portfolios"
                  items={[
                    { href: "/portfolio", label: "Portfolio" },
                    { href: "/portfoliov2", label: "Portfolio V2" },
                    { href: "/portfoliov3", label: "Portfolio V3" },
                    { href: "/portfoliov4", label: "Portfolio V4" },
                    { href: "/portfoliov5", label: "Portfolio V5" },
                    { href: "/portfoliov7", label: "Portfolio V7" },
                    { href: "/portfoliov8", label: "Portfolio V8" },
                    { href: "/portfoliovwrapper", label: "PortfWrapper" },
                  ]}
                />
                <SidebarItem
                  label="Homes"
                  items={[
                    { href: "/home1", label: "Home 1" },
                    { href: "/home2", label: "Home 2" },
                  ]}
                />
                <SidebarItem
                  label="Navbars"
                  items={[
                    { href: "/navbar1", label: "Navbar 1" },
                    { href: "/navbar2", label: "Navbar 2" },
                  ]}
                />
                <SidebarItem
                  label="Functions"
                  items={[
                    { href: "/function1", label: "Function 1" },
                    { href: "/function2", label: "Function 2" },
                  ]}
                />
              </Sidebar.ItemGroup>
            </Sidebar.Items>
          </Sidebar>
        </div>
      )}
    </div>
  );
}

function SidebarItem({ label, items }) {
  return (
    <Sidebar.Item>
      <Dropdown label={label}>
        {items.map((item, index) => (
          <Dropdown.Item key={index} href={item.href}>
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </Sidebar.Item>
  );
}

SidebarItem.propTypes = {
  label: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default DevDropdown;
