// src/splinestatic.jsx <--- just updated a variation of the home anim to this code, without noise so it stays playing.. lets test.
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Spline from "@splinetool/react-spline";

function SplineStatic({ isPlaying }) {
  const splineRef = useRef();

  useEffect(() => {
    if (splineRef.current) {
      splineRef.current.renderOnDemand = !isPlaying;
    }
  }, [isPlaying]);

  return (
    <Spline
      ref={splineRef}
      scene="https://prod.spline.design/Vih3zaDeRcKeEilT/scene.splinecode"
    />
  );
}

SplineStatic.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
};

export default SplineStatic;
