import { Card, ListGroup } from "flowbite-react";
import { HiOutlineFlag } from "react-icons/hi";

const CurriculumLanguagesBox = () => {
  return (
    <Card className="bg-blackthorne-100 dark:bg-blackthorne-500">
      <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
        Languages
      </h2>
      <ListGroup className="text-gray-900 dark:text-gray-400">
        <ListGroup.Item icon={HiOutlineFlag}>
          <span className="mr-2">🇺🇸</span> English
        </ListGroup.Item>
        <ListGroup.Item icon={HiOutlineFlag}>
          <span className="mr-2">🇧🇷</span> Portuguese
        </ListGroup.Item>
        <ListGroup.Item icon={HiOutlineFlag}>
          <span className="mr-2">🇪🇸</span> Spanish
        </ListGroup.Item>
      </ListGroup>
    </Card>
  );
};

export default CurriculumLanguagesBox;
