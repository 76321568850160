import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function NavbarFakeController() {
  const navigate = useNavigate();
  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="p-0">
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Navbar 🔴
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/rounded1")}
              >
                R1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/rounded2")}
              >
                R2
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/rounded3")}
              >
                R3
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/rounded4")}
              >
                R4
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/edgy1")}
              >
                E1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/edgy2")}
              >
                E2
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/edgy3")}
              >
                E3
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/edgy4")}
              >
                E4
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/navbar/edgy5")}
              >
                E5
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default NavbarFakeController;
