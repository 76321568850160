// It is finally working!!!!
// This displays figma inside the projectdescription, which appears inside of project details, which appears inside of portfolio upon clicking on a project.
// src/components/figma/DynamicProjectComponent.jsx
// seeing if error here is causing bug....

import PropTypes from "prop-types";
import projects from "../../projects.json";
import FigmaEmbed from "../figma/FigmaEmbed";
import styles from "./DynamicProjectComponent.module.css";

const DynamicProjectComponent = ({ projectId }) => {
  if (projectId == null) {
    return <div>Project ID is not provided</div>;
  }

  const project = projects.find((p) => p.id === projectId);

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className={styles.figmaContainer}>
      <FigmaEmbed embedUrl={project.FigmaLink} />
    </div>
  );
};

DynamicProjectComponent.propTypes = {
  projectId: PropTypes.string.isRequired,
};

export default DynamicProjectComponent;
