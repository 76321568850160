import { Navbar } from "flowbite-react";
import PropTypes from "prop-types";
import NavbarLinks from "./NavbarLinks";
import styles from "./NavbarRefractedV4.module.css";

function NavbarCollapse({ isMobileMenuOpen, toggleDarkMode, darkMode }) {
  return (
    <Navbar.Collapse
      className={`${isMobileMenuOpen ? "block" : "hidden"} md:block w-full ${styles.navbarCollapse} ${isMobileMenuOpen ? styles.navbarCollapseOpen : ""} xs:dark:bg-opacity-90 xs:backdrop-blur-2xl xs:dark:backdrop-blur-2xl xs:bg-guinera-500 xs:dark:bg-guinera-900`}
      style={{ borderRadius: "16px" }} // Adjust the border radius as needed
    >
      <NavbarLinks toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
    </Navbar.Collapse>
  );
}

NavbarCollapse.propTypes = {
  isMobileMenuOpen: PropTypes.bool.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
  darkMode: PropTypes.bool.isRequired,
};

export default NavbarCollapse;
