// src/components/MetropolisTests.jsx
import Spline from "@splinetool/react-spline";

const MetropolisTests = () => {
  return (
    <div className="antialiased bg-white dark:bg-black">
      <div className="px-4 py-8 mx-auto max-w-screen-2xl lg:px-6 sm:py-16 lg:py-28">
        <div className="space-y-8 lg:space-y-12">
          <div className="space-y-4">
            <h3 className="text-3xl font-bold leading-tight text-gray-900 sm:text-4xl dark:text-white">
              Metropolis Tests
            </h3>
            <div className="relative flex justify-center">
              <Spline scene="https://prod.spline.design/hc1kx5uq5WD8gnoS/scene.splinecode" />
            </div>
            <div className="space-y-6">
              <p className="text-base font-normal text-gray-500 sm:text-lg dark:text-gray-400">
                This is a test scene for Metropolis.
              </p>
              <p>The buttons are clickable</p>
              <p>It works on mobile</p>
              <p>It doenst lag, so far</p>
              <p>There are maany insertions to be made</p>
              <p>
                There are minor adjustments to be made, waiting feedback as well
              </p>
              <p>
                We need to change the secondary buttons, they need to be more
                visible
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MetropolisTests;
