// Imports data from the projects.json in the field "DescriptionThumbLight" and dark.
// projects.json -> DescriptionThumbnail.jsx (here) -> ProjectDetails.jsx -> Portfolio & Portfoliov2.jsx

// Displayed upon clicking on a project card in the portfolio (both Portfolio and Portfoliov2).
// Displayed inside ProjectDetails.jsx

import PropTypes from "prop-types";

const DescriptionThumbnail = ({
  DescriptionThumbLight,
  DescriptionThumbDark,
  altText,
}) => (
  <div className="w-1/3 p-2 border border-gray-300 dark:border-gray-600">
    <img
      src={DescriptionThumbDark}
      alt={altText}
      className="hidden dark:block"
    />
    <img
      src={DescriptionThumbLight}
      alt={altText}
      className="block dark:hidden"
    />
  </div>
);

DescriptionThumbnail.propTypes = {
  DescriptionThumbLight: PropTypes.string.isRequired,
  DescriptionThumbDark: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export default DescriptionThumbnail;
