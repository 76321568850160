const PrivacyPolicy = () => (
  <div className="container p-4 m-32 mx-auto bg-guinera-100 dark:bg-blackthorne-700 text-blackthorne-900 dark:text-guinera-100">
    <h1 className="mb-4 text-2xl font-bold text-brutal-200 dark:text-brutal-200">
      Privacy Policy
    </h1>
    <p className="mb-4">
      Welcome to guimanera.com.br. We are committed to protecting your privacy
      and ensuring that your personal information is handled in a safe and
      responsible manner.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Information We Collect
    </h2>
    <p className="mb-4">
      We collect information from you when you visit our site, register on our
      site, place an order, subscribe to our newsletter, respond to a survey, or
      fill out a form. The types of information we may collect include your
      name, email address, mailing address, phone number, and payment
      information.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      How We Use Your Information
    </h2>
    <p className="mb-4">
      The information we collect from you may be used in the following ways:
      <ul className="list-disc list-inside">
        <li>To personalize your experience</li>
        <li>To improve our website</li>
        <li>To improve customer service</li>
        <li>To process transactions</li>
        <li>To send periodic emails</li>
      </ul>
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      How We Protect Your Information
    </h2>
    <p className="mb-4">
      We implement a variety of security measures to maintain the safety of your
      personal information when you place an order or enter, submit, or access
      your personal information.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Third-Party Disclosure
    </h2>
    <p className="mb-4">
      We do not sell, trade, or otherwise transfer to outside parties your
      personally identifiable information. This does not include trusted third
      parties who assist us in operating our website, conducting our business,
      or servicing you, so long as those parties agree to keep this information
      confidential.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Your Consent
    </h2>
    <p className="mb-4">
      By using our site, you consent to our website privacy policy.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Changes to Our Privacy Policy
    </h2>
    <p className="mb-4">
      If we decide to change our privacy policy, we will post those changes on
      this page.
    </p>
    <h2 className="mb-2 text-xl font-semibold text-brutal-200 dark:text-brutal-200">
      Contacting Us
    </h2>
    <p className="mb-4">
      If there are any questions regarding this privacy policy, you may contact
      us using the information below:
      <br />
      <a
        href="https://guimanera.com.br"
        className="text-gray-400 hover:underline"
      >
        guimanera.com.br
      </a>
      <br />
      Email:{" "}
      <a
        href="mailto:contact@guimanera.com.br"
        className="text-gray-400 hover:underline"
      >
        contact@guimanera.com.br
      </a>
    </p>
  </div>
);

export default PrivacyPolicy;
