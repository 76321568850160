import SplineStatic from "../../splinestatic.jsx";
import SquareAnim from "../../components/squaresanim.jsx";

function HomeV7() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-blackthorne-600">
      <main className="flex-grow w-full px-8 py-16 mt-2 lg:px-24 lg:py-32">
        <section className="py-16">
          <div className="max-w-screen-xl px-4 mx-auto text-center">
            <h1 className="py-4 mb-4 text-5xl font-bold text-gray-900 dark:text-brutal-200 lg:text-6xl">
              Hi, I&apos;m Myr
            </h1>
            <p className="mb-8 text-xl text-gray-700 dark:text-gray-300 lg:text-2xl">
              T-shaped UX Designer
            </p>
            <a
              href="#portfolio"
              className="inline-block px-8 py-4 text-lg font-semibold text-white rounded-lg shadow-md bg-brutal-400 hover:bg-brutal-200 lg:px-10 lg:py-5 lg:text-l"
            >
              View Portfolio
            </a>
          </div>
          <div className="grid grid-cols-1 gap-8 px-4 mx-auto mt-12 max-w-screen-l md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1">
            <div className="p-6 bg-white rounded-lg shadow-lg dark:bg-blackthorne-500">
              <SplineStatic />
            </div>
            <div className="p-6 bg-white rounded-lg shadow-lg dark:bg-blackthorne-500">
              <SquareAnim />
            </div>
            {/* Add more components or content as needed */}
          </div>
        </section>
      </main>
    </div>
  );
}

export default HomeV7;
