//after create and add curriculumToolbox
import CurriculumAvatarBox from "../../components/CurriculumAvatarBox.jsx";
import CurriculumSkillsBox from "../../components/CurriculumSkillsBox.jsx";
import CurriculumLanguagesBox from "../../components/CurriculumLanguagesBox.jsx";
import CurriculumSocialBox from "../../components/CurriculumSocialBox.jsx";
import CurriculumExperienceBox from "../../components/CurriculumExperienceBox.jsx";
import CurriculumLatestProjectsBox from "../../components/CurriculumLatestProjectsBox.jsx";

const CurriculumRefracted = () => {
  return (
    <div className="m-32 curriculum-refracted-container">
      <CurriculumAvatarBox />
      <CurriculumSkillsBox />
      <CurriculumLanguagesBox />
      <CurriculumSocialBox />
      <CurriculumExperienceBox />
      <CurriculumLatestProjectsBox />
    </div>
  );
};

export default CurriculumRefracted;
