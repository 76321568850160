import PortfolioV7 from "./PortfolioV7.jsx";
// start

const PortfolioWrapper = () => {
  return (
    <div
      className="text-black rounded-6xl center p-14 dark:text-white card-effect bg-blackthorne-100 dark:bg-blackthorne-700"
      style={{
        width: "100%",
        maxWidth: "1400px",
        margin: "0 auto",
        padding: "12rem",
      }}
    >
      <PortfolioV7 />
    </div>
  );
};

export default PortfolioWrapper;
