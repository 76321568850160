// src/components/AboutThisWebsite.jsx

const AboutThisWebsite = () => {
  const websiteInfo = {
    description:
      "This website was built using React, Vite, and Tailwind CSS. It is open source and available on GitHub.",
    githubRepo: "https://github.com/your-username/myr-portfolio",
    openSource: true,
  };

  return (
    <div className="p-6 rounded-lg shadow-md opacity:50 bg-white/70 backdrop-blur-lg dark:bg-blackthorne-800 dark:bg-opacity-40">
      <h2 className="mb-4 text-2xl font-bold text-gray-900 dark:text-pendle-300 dark:bg-opacity-25">
        About This Website
      </h2>
      <p className="mb-4 text-gray-700 dark:text-guinera-300">
        {websiteInfo.description}
      </p>
      <p className="mb-4 text-gray-700 dark:text-guinera-300">
        GitHub Repository:{" "}
        <a
          href={websiteInfo.githubRepo}
          target="_blank"
          rel="noopener noreferrer"
          className="text-pendle-300 hover:underline"
        >
          {websiteInfo.githubRepo}
        </a>
      </p>
      <p className="text-gray-700 dark:text-gray-300">
        Open Source: {websiteInfo.openSource ? "Yes" : "No"}
      </p>
    </div>
  );
};

export default AboutThisWebsite;
