// Implementing this to be a dropdown menu for the navbar
// It should display commands to control things in page like no animation, play / pause animation, rewind animation, change to dark mode, change animation (all icons maybe)
// Displayed on Navbar

import { useState } from "react";
import AnimationController from "./AnimationController";
import DevMenuExplainer from "./DevMenuExplainer";

function NavbarDropdown() {
  const [enableAnimations, setEnableAnimations] = useState(true);
  const [darkMode, setDarkMode] = useState(true);
  const [currentAnimation, setCurrentAnimation] = useState("SplineAnim");

  const toggleAnimations = () => {
    setEnableAnimations(!enableAnimations);
    console.log("Animations enabled:", !enableAnimations);
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    console.log("Dark mode:", !darkMode);
  };

  const changeAnimation = () => {
    console.log("Change animation clicked");
    setCurrentAnimation(
      currentAnimation === "SplineAnim" ? "SquareAnim" : "SplineAnim",
    );
  };

  return (
    <div className=" dark:bg-transparent-90">
      <button
        id="dropdownMenuIconButton"
        data-dropdown-toggle="dropdownDots"
        data-dropdown-trigger="hover"
        className="inline-flex items-center p-2 text-sm font-medium text-center rounded-lg text-pendle-900 bg-pendle-300 hover:bg-brutal-100 focus:ring-4 focus:outline-none dark:text-brutal-700 focus:ring-guinera-500 dark:bg-brutal-200 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        type="button"
      >
        <svg
          className="w-2 h-2"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 4 15"
        >
          <path d="M3.5 1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 6.041a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Zm0 5.959a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
        </svg>
      </button>
      <div
        id="dropdownDots"
        className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow bg-opacity-70 dark:bg-opacity-80 w-72 dark:bg-guinera-900 backdrop-blur-3xl dark:divide-gray-600"
      >
        <DevMenuExplainer />
        <ul
          className="p-3 space-y-1 text-sm text-gray-700 dark:text-gray-200"
          aria-labelledby="dropdownMenuIconButton"
        >
          <li>
            <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <label className="inline-flex items-center w-full cursor-pointer">
                <input
                  type="checkbox"
                  checked={enableAnimations}
                  onChange={toggleAnimations}
                  className="sr-only peer"
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-pendle-600 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-brutal-200"></div>
                <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                  Enable animations
                </span>
              </label>
            </div>
          </li>
          <li>
            <div className="flex p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
              <label className="inline-flex items-center w-full cursor-pointer">
                <input
                  type="checkbox"
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  className="sr-only peer"
                />
                <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-pendle-600 rounded-full peer dark:bg-gray-600 peer-checked:after:translate-x-full rtl:peer-checked:after:translate-x-[-100%] peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-500 peer-checked:bg-brutal-200"></div>
                <span className="text-sm font-medium text-gray-900 ms-3 dark:text-gray-300">
                  Dark Mode
                </span>
              </label>
            </div>
          </li>
          <li>
            <button
              onClick={changeAnimation}
              className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
            >
              Change animation
            </button>
          </li>
          <li>
            <button className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Pause
            </button>
          </li>
          <li>
            <button className="block w-full px-4 py-2 text-left hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Change Responsivity
            </button>
          </li>
        </ul>
        <AnimationController
          currentAnimation={currentAnimation}
          enableAnimations={enableAnimations}
        />
      </div>
    </div>
  );
}

export default NavbarDropdown;
