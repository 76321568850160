// creating new ideas here
// btw, ideas for portfoliov5 -> A version with thumbnails in 3D from spline...

import { useState, useEffect } from "react";
import PortfolioCategories from "../../components/PortfolioCategories.jsx";
import ProjectDetailsV2 from "../../components/ProjectDetailsV2.jsx";
import projectsData from "../../projects.json";
import ProjectThumbnailsV4Grid from "../../components/ProjectThumbnailsV4Grid.jsx";
import "./PortfolioV4.css";

const PortfolioV4 = () => {
  const [selectedCategory, setSelectedCategory] = useState("UX Design");
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const displayLimit = 11;

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  const filteredProjects =
    selectedCategory === "All"
      ? projects
      : projects.filter((project) => project.category === selectedCategory);

  const handleProjectClick = (project) => {
    if (selectedProject && selectedProject.id === project.id) {
      setSelectedProject(null);
    } else {
      setSelectedProject(project);
    }
  };

  return (
    <div className="mt-32 portfolio-container">
      <PortfolioCategories
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
      {/* Spacer div added here */}
      <div style={{ height: "50px" }}></div>
      <div className="portfolio-grid">
        <ProjectThumbnailsV4Grid
          projects={filteredProjects.slice(0, displayLimit)}
          onProjectClick={handleProjectClick}
        />
      </div>
      <div style={{ height: "20px" }}></div>
      {selectedProject && (
        <ProjectDetailsV2
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
};

export default PortfolioV4;
