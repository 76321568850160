// same logic, no superimpose yet
// adding superimpose now
// debugging superimpose now
// debugging
// debugging v2 (live problem)

import React from "react";
import PropTypes from "prop-types";
import ProjectDescriptionV2 from "./ProjectDescriptionV2";
import TechnologyStack from "./TechnologyStack";

const ProjectDetailsV4 = ({ project, onClose }) => {
  console.log("ProjectDetailsV4 rendered with project:", project);

  const FullProjectPageComponent = project.fullProjectPage
    ? React.lazy(() =>
        import(`./${project.fullProjectPage}`).catch((error) => {
          console.error("Error loading full project page component:", error);
          return null;
        }),
      )
    : null;

  console.log("FullProjectPageComponent:", FullProjectPageComponent);

  return (
    <div>
      <div>
        {FullProjectPageComponent ? (
          <React.Suspense fallback={<div>Loading...</div>}>
            <FullProjectPageComponent project={project} />
          </React.Suspense>
        ) : (
          <>
            <h1>{project.name}</h1> {/* Display the project name */}
            <ProjectDescriptionV2
              description={project.description}
              projectId={project.id}
            />
            <TechnologyStack techTools={project.techTools} />
          </>
        )}
      </div>
      <button
        onClick={onClose}
        className="relative pb-12 mt-18 mt-14 bg-inherit opacity-90 text-brutal-300 "
      >
        Close
      </button>
    </div>
  );
};
ProjectDetailsV4.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    techTools: PropTypes.arrayOf(PropTypes.string).isRequired,
    fullProjectPage: PropTypes.string, // Optional property to determine if a custom page should be used
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProjectDetailsV4;
