// src/squaresanim.jsx <--- actually, spline hand in pixels i think, being used on the Experiment page.
// src/squaresanim.jsx <--- actually, spline hand in pixels i think, being used on the Experiment page. Yes
import { useEffect, useRef, useCallback } from "react";
import Spline from "@splinetool/react-spline";
import PropTypes from "prop-types";

function SquareAnim({ isPlaying }) {
  const splineRef = useRef(null);

  const handleLoad = (spline) => {
    splineRef.current = spline;
    if (isPlaying) {
      spline.play();
      animate();
    }
  };

  const animate = useCallback(() => {
    if (splineRef.current && isPlaying) {
      splineRef.current.render();
      requestAnimationFrame(animate);
    }
  }, [isPlaying]);

  useEffect(() => {
    if (splineRef.current) {
      if (isPlaying) {
        splineRef.current.play();
        animate();
      } else {
        splineRef.current.stop();
      }
    }
  }, [isPlaying, animate]);

  return (
    <Spline
      scene="https://prod.spline.design/nRWCUtwqTjLcXhBW/scene.splinecode"
      renderOnDemand={false}
      onLoad={handleLoad}
    />
  );
}

SquareAnim.propTypes = {
  isPlaying: PropTypes.bool.isRequired,
};

export default SquareAnim;
