// src/components/GoalsList.jsx

import PropTypes from "prop-types";

const GoalsList = ({ goals = [] }) => {
  return (
    <div>
      <h3>Goals</h3>
      <ul>
        {goals.map((goal, index) => (
          <li key={index}>{goal}</li>
        ))}
      </ul>
    </div>
  );
};

GoalsList.propTypes = {
  goals: PropTypes.arrayOf(PropTypes.string),
};

export default GoalsList;
