// Displayed on ProjectThumbnailsV2Grid (Portfolio)
// Working properly

import PropTypes from "prop-types";

const ProjectThumbnailV2 = ({ project, onClick }) => {
  return (
    <div className="mb-6 space-y-3" onClick={onClick}>
      <img
        className="object-cover w-full mb-6 rounded-lg shadow-lg"
        src={project.SelectorThumbLight}
        alt={project.name}
      />
      <span className="bg-indigo-100 text-indigo-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-indigo-900 dark:text-indigo-300">
        <svg
          aria-hidden="true"
          className="w-3 h-3 mr-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
        </svg>
        {project.tags.join(", ")}
      </span>
      <h3 className="text-2xl font-bold leading-tight text-primary-100 dark:text">
        {project.name}
      </h3>
      <p className="text-lg font-normal text-gray-500 dark:text-gray-400">
        {project.shortDescription}
      </p>
      <div className="flex items-center gap-4">
        <a
          href={project.CaseStudy}
          className="text-primary-700 hover:underline"
        >
          Case study
        </a>
        <a
          href={project.LivePreview}
          title=""
          className="inline-flex items-center justify-center px-5 py-2.5 text-sm font-medium text-center text-gray-900 bg-white border border-gray-200 rounded-lg shrink-0 focus:outline-none hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          role="button"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5 mr-2 -ml-1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5z" />
          </svg>
          Live Preview
        </a>
      </div>
    </div>
  );
};

ProjectThumbnailV2.propTypes = {
  project: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailV2;
