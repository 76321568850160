// This is basically a page to display our experiment component, which is an experiment with spline and 3D animations with react. There can also be multiple ones
import Experimento from "../components/experimento.jsx";
import SquareAnim from "../components/squaresanim.jsx";
import DynamicSpline from "../components/DynamicSpline.jsx";
import SplineRandom from "../components/SplineRandom.jsx";

function Experiment() {
  return (
    <div style={{ height: "1", width: "150vw" }}>
      <SquareAnim />
      <Experimento />
      <DynamicSpline />
      <SplineRandom />
    </div>
  );
}

export default Experiment;
