import { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Button } from "flowbite-react";
import CategoryIcons from "./CategoryIcons";

function PortfolioCategories({ selectedCategory, onSelectCategory }) {
  const categories = useMemo(
    () => [
      "All",
      "UX Design",
      "3D",
      "Graphic & Motion",
      "Front End",
      "Branding",
      "Art",
    ],
    [],
  );

  useEffect(() => {
    if (!categories.includes(selectedCategory)) {
      onSelectCategory("All");
    }
  }, [selectedCategory, categories, onSelectCategory]);

  const handleCategoryClick = (category) => {
    onSelectCategory(category);
  };

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {categories.map((category) => (
        <Button
          key={category}
          onClick={() => handleCategoryClick(category)}
          className={`px-3 py-2 text-xs font-medium text-center inline-flex items-center dark:text-guinera-400 text-guinera-800 bg-guinera-200 rounded-lg dark:hover:text-guinera-200 hover:bg-guinera-400 focus:ring-4 focus:outline-none focus:ring-transparent dark:bg-blackthorne-600 dark:hover:bg-blackthorne-500 dark:border-guinera-600 dark:focus:ring-pendle-800 ${
            category === selectedCategory
              ? "ring-2 dark-ring-2 ring-transparent ring-pendle-100 dark:bg-guinera-900 text-guinera-700 dark:text-guinera-200 bg-guinera-400 dark:ring-pendle-800 border-transparent dark:border-transparent"
              : ""
          }`}
        >
          <div className="flex items-center">
            <CategoryIcons category={category} className="w-3 h-3 me-2" />
            <span className="ml-1">{category}</span>
          </div>
        </Button>
      ))}
    </div>
  );
}

PortfolioCategories.propTypes = {
  selectedCategory: PropTypes.string.isRequired,
  onSelectCategory: PropTypes.func.isRequired,
};

export default PortfolioCategories;
