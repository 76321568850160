"use client";

import { Card, Button } from "flowbite-react";
import {
  FaEnvelope,
  FaTelegramPlane,
  FaMapMarkerAlt,
  FaInstagram,
  FaGithub,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

const ContactDev = () => {
  return (
    <div className="container p-4 m-32 mx-auto">
      <Card className="mb-4 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
          Contact Information
        </h2>
        <div className="flex flex-col items-center pb-10">
          <div className="flex mt-4 space-x-3 md:mt-6">
            <Button className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800">
              <FaEnvelope className="w-5 h-5 mr-2" /> Pls dont spam me
            </Button>
            <Button
              href="https://t.me/Myr_Grovve"
              className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800"
            >
              <FaTelegramPlane className="w-5 h-5 mr-2" /> myr_grovve
            </Button>
            <Button className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800">
              <FaMapMarkerAlt className="w-5 h-5 mr-2" /> São Paulo, Brazil
            </Button>
          </div>
        </div>
      </Card>

      <Card className="mb-4 bg-blackthorne-100 dark:bg-blackthorne-500">
        <h2 className="mb-4 text-2xl font-bold text-brutal-900 dark:text-brutal-100">
          Follow Me
        </h2>
        <div className="flex flex-col items-center pb-10">
          <div className="flex mt-4 space-x-3 md:mt-6">
            <Button
              href="https://www.instagram.com/gmnrart"
              className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800"
            >
              <FaInstagram className="w-5 h-5 mr-2" /> Instagram
            </Button>
            <Button
              href="https://github.com/gmnr"
              className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800"
            >
              <FaGithub className="w-5 h-5 mr-2" /> GitHub
            </Button>
            <Button
              href="https://twitter.com/gmnrmyr"
              className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800"
            >
              <FaTwitter className="w-5 h-5 mr-2" /> Twitter
            </Button>
            <Button
              href="https://www.linkedin.com/in/gmnr9"
              className="flex items-center text-gray-900 bg-guinera-400 dark:bg-guinera-600 dark:text-brutal-80 border-guinera-100 dark:border-guinera-800"
            >
              <FaLinkedin className="w-5 h-5 mr-2" /> LinkedIn
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContactDev;
