import PropTypes from "prop-types";
import ProjectThumbnailV6 from "./ProjectThumbnailV6.jsx";
import styles from "./ProjectThumbnailsV6Grid.module.css";

const ProjectThumbnailsV6Grid = ({
  projects,
  onProjectClick,
  selectedProject,
}) => {
  return (
    <div className={styles.grid}>
      {" "}
      {projects.map((project) => (
        <ProjectThumbnailV6
          key={project.id}
          project={project}
          onClick={() => onProjectClick(project)}
          isSelected={!!selectedProject && selectedProject.id === project.id}
        />
      ))}
    </div>
  );
};

ProjectThumbnailsV6Grid.propTypes = {
  projects: PropTypes.array.isRequired,
  onProjectClick: PropTypes.func.isRequired,
  selectedProject: PropTypes.object,
};

export default ProjectThumbnailsV6Grid;
