import { Link } from "react-router-dom";
import { Navbar } from "flowbite-react";
import NavbarLogo from "./NavbarLogo";

export function NavbarRound1() {
  return (
    <div className="w-full navbar">
      <Navbar fluid rounded>
        <Navbar.Brand as={Link} href="https://flowbite-react.com">
          <NavbarLogo darkMode={false} />
          <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
            Flowbite React
          </span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Navbar.Link href="#" active>
            Home
          </Navbar.Link>
          <Navbar.Link as={Link} href="#">
            About
          </Navbar.Link>
          <Navbar.Link href="#">Services</Navbar.Link>
          <Navbar.Link href="#">Pricing</Navbar.Link>
          <Navbar.Link href="#">Contact</Navbar.Link>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
