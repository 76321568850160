import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function DesignSystemsController() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div className="p-0">
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Design Systems 🔴
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/colors1")}
              >
                Colors 1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/colors2")}
              >
                Colors 2
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/colors3")}
              >
                Colors 3
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/typography1")}
              >
                Typo 1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/typography2")}
              >
                Typo 2
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/typography3")}
              >
                Typo 3
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/icons1")}
              >
                Icons 1
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/icons2")}
              >
                Icons 2
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/design-system/icons3")}
              >
                Icons 3
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Extra Pages
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button size="xs" onClick={() => handleNavigation("/extra1")}>
                Extra 1
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/extra2")}>
                Extra 2
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/extra3")}>
                Extra 3
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default DesignSystemsController;
