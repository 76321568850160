import { useState } from "react";
import NavbarTesterV3 from "./NavbarTesterV3";
import { TextInput, Button } from "flowbite-react";

function HiddenMenu() {
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [password, setPassword] = useState("");

  const handleUnlock = () => {
    console.log("Password entered:", password);
    if (password === "fezinha123" || password === "123") {
      setIsUnlocked(true);
      setShowPasswordInput(false);
      console.log("Unlocked successfully");
    } else {
      alert("Incorrect password");
    }
  };

  const handleButtonClick = () => {
    if (!isUnlocked) {
      setShowPasswordInput(!showPasswordInput);
    }
  };

  return (
    <footer className="p-0 text-white bg-transparent">
      <div className="container mx-auto text-center">
        <div className="relative inline-block text-left">
          {!isUnlocked && (
            <button
              id="dropdownDividerButton"
              className="text-black bg-guinera-200 hover:bg-primary-200 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center dark:bg-primary-200 dark:hover:bg-primary-300 dark:focus:ring-gray-800"
              type="button"
              onClick={handleButtonClick}
            >
              Dev
              <svg
                className="w-1 h-1 ms-2"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 15 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 4 4 4-4"
                />
              </svg>
            </button>
          )}
          {showPasswordInput && !isUnlocked && (
            <div className="flex inline-flex items-center ml-4 space-x-2">
              <TextInput
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
                className="password-input"
              />
              <Button onClick={handleUnlock} className="unlock-button">
                Unlock
              </Button>
            </div>
          )}
        </div>
      </div>
      {isUnlocked && <NavbarTesterV3 />}
    </footer>
  );
}

export default HiddenMenu;
