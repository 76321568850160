// trying to program this V2 see which one i like more
// DUPE - TEST

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import NavbarLogo from "./NavbarLogo";
import NavbarLinks from "./NavbarLinks";
import NavbarDropdown from "./NavbarDropdown";
import styles from "./navbarrefracted.module.css";

function NavbarRefractedV5() {
  const [darkMode, setDarkMode] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (darkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav
      className={`${styles.navbar} fixed top-0 left-0 z-50 w-full bg-gray-500 bg-opacity-50 border-gray-200 backdrop-blur-md dark:bg-gray-900 dark:bg-opacity-50 dark:backdrop-blur-md`}
    >
      <div className="flex flex-wrap items-center justify-between p-4 mx-auto max-2w-screen-2xl">
        <Link
          to="/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <NavbarLogo darkMode={darkMode} />
          <NavbarDropdown />
        </Link>
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className={`hamburger-menu inline-flex items-center justify-center w-10 h-10 p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600`}
          aria-controls="navbar-default"
          aria-expanded="false"
          onClick={toggleMobileMenu}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <div
          className={`${isMobileMenuOpen ? "block" : "hidden"} w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <NavbarLinks toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
        </div>
      </div>
    </nav>
  );
}

export default NavbarRefractedV5;
