import "./Unlocked.css";
import HomeController from "./devmenu/HomeController.jsx";
import PortifController from "./devmenu/PortifController.jsx";
import AboutController from "./devmenu/AboutController.jsx";
import ExtrasController from "./devmenu/ExtrasController.jsx";
import NavbarFakeController from "./devmenu/NavbarFakeController.jsx";
import CacheDevToolsFakeController from "./devmenu/CacheDevToolsFakeController.jsx";
import DesignSystemsController from "./devmenu/DesignSystemsController.jsx";
import SquaredButtons4Controller from "./devmenu/SquaredButtons4Controller";
import SquaredButtons4Controller2 from "./devmenu/SquaredButtons4Controller2";
import SquaredButtons4Controller3 from "./devmenu/SquaredButtons4Controller3";

function Unlocked() {
  return (
    <div className="unlocked-content">
      <h1 className="mb-4 text-2xl font-bold">The Matrix</h1>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        <div className="item bordered">
          <div className="mt-4">
            <HomeController />
          </div>
        </div>
        <div className="item bordered">
          <div className="mt-4">
            <PortifController />
          </div>
        </div>
        <div className="item bordered">
          <div className="mt-4">
            <AboutController />
          </div>
        </div>
        <div className="item bordered">
          <div className="mt-4">
            <ExtrasController />
          </div>
        </div>
        <div className="item bordered">
          <NavbarFakeController />
        </div>
        <div className="item bordered">
          <CacheDevToolsFakeController />
        </div>
        <div className="item bordered">
          <DesignSystemsController />
        </div>
        <div className="item bordered">
          <SquaredButtons4Controller />
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mt-4">
        <div className="item bordered">
          <SquaredButtons4Controller2 />
        </div>
        <div className="item bordered">
          <SquaredButtons4Controller3 />
        </div>
        <div className="item bordered">
          <h2 className="text-xl font-semibold">We hope you have enjoyed.</h2>
          <p>---la_matraca---matrix.exe</p>
        </div>
      </div>
    </div>
  );
}

export default Unlocked;
