import PropTypes from "prop-types";

const logodark = "https://grovve.xyz/wp-content/uploads/2024/08/logo.svg";
const logo = "https://grovve.xyz/wp-content/uploads/2024/08/logodark.svg";

function NavbarLogo({ darkMode }) {
  const styles = {
    height: "1rem",
    color: darkMode ? "white" : "black",
  };

  return <img src={darkMode ? logodark : logo} style={styles} alt="Myr Logo" />;
}

NavbarLogo.propTypes = {
  darkMode: PropTypes.bool.isRequired,
};

export default NavbarLogo;
