// same logic, no superimpose yet
// adding superimpose now
// debugging superimpose now

import React from "react";
import PropTypes from "prop-types";
import ProjectDescriptionV2 from "./ProjectDescriptionV2";
import TechnologyStack from "./TechnologyStack";

const ProjectDetailsV3 = ({ project, onClose }) => {
  const FullProjectPageComponent = project.fullProjectPage
    ? React.lazy(() => import(`./${project.fullProjectPage}`))
    : null;

  return (
    <div>
      <div>
        {FullProjectPageComponent ? (
          <React.Suspense fallback={<div>Loading...</div>}>
            <FullProjectPageComponent project={project} />
          </React.Suspense>
        ) : (
          <>
            <h1>{project.name}</h1> {/* Display the project name */}
            <ProjectDescriptionV2
              description={project.description}
              projectId={project.id}
            />
            <TechnologyStack techTools={project.techTools} />
          </>
        )}
      </div>

      <button onClick={onClose} className="mt-4 text-red-500">
        Close
      </button>
    </div>
  );
};

ProjectDetailsV3.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    techTools: PropTypes.arrayOf(PropTypes.string).isRequired,
    fullProjectPage: PropTypes.string, // Optional property to determine if a custom page should be used
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProjectDetailsV3;
