/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import PortfolioCategories from "../../components/PortfolioCategories.jsx";
import ProjectDetailsV4 from "../../components/ProjectDetailsV4.jsx";
import projectsData from "../../projects.json";
import ProjectThumbnailsV6Grid from "../../components/ProjectThumbnailsV6Grid.jsx";
import "../PortfolioV6.css";
/* eslint-enable no-unused-vars */

const PortfolioV7 = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const displayLimit = 12;
  const projectDetailsRef = useRef(null);

  useEffect(() => {
    setProjects(projectsData);
  }, []);

  const filteredProjects =
    selectedCategory === "All"
      ? projects
      : projects.filter((project) => project.category === selectedCategory);

  const handleProjectClick = (project) => {
    if (selectedProject && selectedProject.id === project.id) {
      setSelectedProject(null);
    } else {
      setSelectedProject({ ...project, id: project.id.toString() });
      setTimeout(() => {
        if (projectDetailsRef.current) {
          projectDetailsRef.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    }
  };

  return (
    <div className="relative pb-12 mt-32 portfolio-container bg-blackthorne-100 dark:bg-blackthorne-700">
      <PortfolioCategories
        selectedCategory={selectedCategory}
        onSelectCategory={setSelectedCategory}
      />
      <div style={{ height: "50px" }}></div>
      <div className="portfolio-grid">
        <ProjectThumbnailsV6Grid
          projects={filteredProjects.slice(0, displayLimit)}
          onProjectClick={handleProjectClick}
          selectedProject={selectedProject}
        />
      </div>
      <div style={{ height: "20px" }}></div>
      {selectedProject && (
        <div ref={projectDetailsRef} className="card-effect">
          <ProjectDetailsV4
            project={selectedProject}
            onClose={() => setSelectedProject(null)}
          />
        </div>
      )}
    </div>
  );
};

export default PortfolioV7;
