// Displayed on Portfolio
// Import ProjectThumbnailV2.jsx and display them on a grid.
// Working properly

import PropTypes from "prop-types";
import ProjectThumbnailV2 from "./ProjectThumbnailV2.jsx";
import styles from "./ProjectThumbnailsV2Grid.module.css";

const ProjectThumbnailsV2Grid = ({ projects, onProjectClick }) => {
  return (
    <div className={styles.grid}>
      {projects.map((project) => (
        <ProjectThumbnailV2
          key={project.id}
          project={project}
          onClick={() => onProjectClick(project)}
        />
      ))}
    </div>
  );
};

ProjectThumbnailsV2Grid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProjectClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailsV2Grid;
