const Soon = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-gray-900 bg-white dark:bg-blackthorne-600 dark:text-gray-100">
      <h1 className="text-4xl font-bold">Coming Soon</h1>
      <p className="mt-4 text-lg">Stay tuned for updates!</p>
    </div>
  );
};

export default Soon;
