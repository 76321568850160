import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function HomeController() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Homes🟢
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Button size="xs" onClick={() => handleNavigation("/dev/home")}>
                D1
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev2")}>
                D2
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev3")}>
                D3
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev4")}>
                D4
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev5")}>
                D5
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev6")}>
                D6
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev7")}>
                D7
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev8")}>
                D8
              </Button>
              <Button size="xs" onClick={() => handleNavigation("/dev/homev9")}>
                D9
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/homev10")}
              >
                D10
              </Button>
              <Button
                size="xs"
                onClick={() => handleNavigation("/dev/homev11")}
              >
                D11
              </Button>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default HomeController;
