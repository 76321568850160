// crashes prob cause of the blur css thing / blur or something.

import SquareAnim from "../../components/squaresanim.jsx";
import "./HomeV5.css"; // Import the CSS file

function HomeV5() {
  return (
    <div
      className="pt-0 bg-white home-container dark:bg-gray-800 md:pt-0"
      style={{
        backdropFilter: "blur(8px)",
        backgroundColor: "transparent",
        position: "relative",
      }}
    >
      <SquareAnim />
    </div>
  );
}

export default HomeV5;
