// unable to select

// src/components/TypewriterAnimation.jsx

import TypingAnimation from "./TypingAnimation"; // Adjust the import path as necessary

const TypewriterAnimation = () => {
  return (
    <div className="flex items-center justify-center min-h-screen p-10 bg-transparent select-none">
      <div className="w-max">
        <h1 className="pr-5 overflow-hidden text-4xl font-bold border-r-4 md:text-6xl text-guinera-900 dark:text-guinera-400 animate-typing whitespace-nowrap border-r-guinera-400">
          {" "}
          <TypingAnimation />
        </h1>
      </div>
    </div>
  );
};

export default TypewriterAnimation;
