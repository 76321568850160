//The ProjectDescriptionV2 component is responsible for displaying a project's description and dynamically loading additional project details using the DynamicProjectComponent (which is not yet working) It is integrated into the ProjectDetails component, which is displayed when a project thumbnail is clicked in the Portfolio component.

import PropTypes from "prop-types";
import DynamicProjectComponent from "./figma/DynamicProjectComponent.jsx";
import projects from "../projects.json";

const ProjectDescriptionV2 = ({ description, projectId }) => {
  const project = projects.find((p) => p.id === projectId);

  return (
    <div className="flex flex-col w-full p-2 ml-4 border border-gray-300 dark:border-gray-600">
      <p className="text-gray-700 dark:text-gray-300">{description}</p>
      <div className="flex items-center justify-center flex-grow">
        {project ? (
          <DynamicProjectComponent projectId={projectId} />
        ) : (
          <div>Project not found</div>
        )}
      </div>
    </div>
  );
};

ProjectDescriptionV2.propTypes = {
  description: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};

export default ProjectDescriptionV2;
