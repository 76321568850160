// crashes prob cause of the blur css thing / blur or something.

import SquareAnim from "../../components/squaresanim.jsx";
import "./HomeV6.css"; // Import the CSS file

function HomeV6() {
  return (
    <div
      className="pt-0 home-container md:pt-0"
      style={{
        backdropFilter: "blur(8px)",
        backgroundColor: "black",
        position: "relative",
      }}
    >
      <SquareAnim />
    </div>
  );
}

export default HomeV6;
