const HelloWorld = () => {
  return (
    <div style={{ color: "pink", fontSize: "24px", margin: "10px" }}>
      Hello World
    </div>
  );
};

const ThreeDScene = () => {
  return (
    <div>
      {Array.from({ length: 10 }).map((_, index) => (
        <HelloWorld key={index} />
      ))}
    </div>
  );
};

export default ThreeDScene;
