"use client";

import { Footer } from "flowbite-react";
import { BsGithub, BsInstagram, BsTwitter, BsLinkedin } from "react-icons/bs";
import HiddenMenu from "./HiddenMenu";

export function FooterV2() {
  return (
    <Footer bgDark>
      <div className="w-full px-4 py-6 bg-blackthorne-50 dark:bg-blackthorne-800 sm:flex sm:items-center sm:justify-between">
        <div className="flex items-center space-x-4 text-gray-500 dark:text-gray-400">
          <a href="https://guimanera.com.br/" className="super-small-text">
            © Guilherme Manera
          </a>
          <span>|</span>
          <a href="/terms-of-service" className="super-small-text">
            Terms of Service
          </a>
          <span>|</span>
          <a href="/privacy-policy" className="super-small-text">
            Privacy Policy
          </a>
        </div>
        <div className="flex items-center mt-4 space-x-6 sm:mt-0 sm:justify-center">
          <HiddenMenu />
          <Footer.Icon
            href="https://www.linkedin.com/in/gmnr9/"
            icon={BsLinkedin}
          />
          <Footer.Icon
            href="https://www.instagram.com/gmnrart/"
            icon={BsInstagram}
          />
          <Footer.Icon href="https://twitter.com/gmnrart/" icon={BsTwitter} />
          <Footer.Icon href="https://github.com/gmnrmyr/" icon={BsGithub} />
        </div>
      </div>
    </Footer>
  );
}

export default FooterV2;
