// (1) src/pages/Home.jsx <--- feel free to swap SplineAnim to SplineStatic to see which one works better. Right now more in the mood of SplineStatic, but we can always change that
// (1) or maybe even create a script to swap automatically in the future! Would be nice, to let animations rolling on the background and the user to interact with.
// (2) #TODO so, I still want to implement that typing animation here... on the "Oustisder & Hard Worker" text. I think it would be cool to have it here. So it would be changing
// (2) to other cool phrases
// (3) #TODO So, the toogle animation on and off still something would be nice to implement, maybe a way to click and change the background animation as well
// (4) #TODO Maybe make the text container "less selectable" so in that way, the spline animation behind it would be easier for the user to click and interact with the animation.

import SplineStatic from "../../splinestatic.jsx";
import HomeText from "../../components/HomeText.jsx";

function Home() {
  return (
    <div
      className="home-container"
      style={{
        paddingTop: "4px",
        backdropFilter: "blur(8px)",
        backgroundColor: "transparent",
        position: "relative",
      }}
    >
      <SplineStatic />
      <HomeText />
    </div>
  );
}

export default Home;
