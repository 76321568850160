// Displayed on About
// src/components/SkillDetails.jsx

import PropTypes from "prop-types";

const SkillDetails = ({ skills }) => {
  return (
    <div>
      {skills.map((skill, index) => (
        <div key={index}>
          <h3>{skill.name}</h3>
          <p>{skill.level}</p>
        </div>
      ))}
    </div>
  );
};

SkillDetails.propTypes = {
  skills: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SkillDetails;
