// src/components/ProjectThumbnailsV4Grid.jsx

import PropTypes from "prop-types";
import ProjectThumbnailV4 from "./ProjectThumbnailV4.jsx";
import styles from "./ProjectThumbnailsV4Grid.module.css";

const ProjectThumbnailsV4Grid = ({ projects, onProjectClick }) => {
  return (
    <div className={styles.grid}>
      {projects.map((project) => (
        <ProjectThumbnailV4
          key={project.id}
          project={project}
          onClick={() => onProjectClick(project)}
        />
      ))}
    </div>
  );
};

ProjectThumbnailsV4Grid.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.object).isRequired,
  onProjectClick: PropTypes.func.isRequired,
};

export default ProjectThumbnailsV4Grid;
