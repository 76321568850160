import { useState } from "react";
import NavbarRefracted from "./NavbarRefracted";
import NavbarRefractedV2 from "./NavbarRefractedV2";
import NavbarRefractedV3 from "./NavbarRefractedV3";
import NavbarRefractedV4 from "./NavbarRefractedV4";
import NavbarRefractedV5 from "./NavbarRefractedV5";

function NavbarTester() {
  const navbars = [
    NavbarRefracted,
    NavbarRefractedV2,
    NavbarRefractedV3,
    NavbarRefractedV4,
    NavbarRefractedV5,
  ];

  const [currentNavbarIndex, setCurrentNavbarIndex] = useState(0);

  const toggleNavbarVersion = (index) => {
    setCurrentNavbarIndex(index);
  };

  const CurrentNavbar = navbars[currentNavbarIndex];

  return (
    <div className="mt-4">
      <div className="flex mb-4 space-x-2">
        <button
          onClick={() => toggleNavbarVersion(0)}
          className="p-2 text-white bg-blue-500 rounded"
        >
          Navbar Refracted
        </button>
        <button
          onClick={() => toggleNavbarVersion(1)}
          className="p-2 text-white bg-blue-500 rounded"
        >
          Navbar Refracted V2
        </button>
        <button
          onClick={() => toggleNavbarVersion(2)}
          className="p-2 text-white bg-blue-500 rounded"
        >
          Navbar Refracted V3
        </button>
        <button
          onClick={() => toggleNavbarVersion(3)}
          className="p-2 text-white bg-blue-500 rounded"
        >
          Navbar Refracted V4
        </button>
        <button
          onClick={() => toggleNavbarVersion(4)}
          className="p-2 text-white bg-blue-500 rounded"
        >
          Navbar Refracted V5
        </button>
      </div>
      <CurrentNavbar />
    </div>
  );
}

export default NavbarTester;
