import { useNavigate } from "react-router-dom";
import { Accordion, Button } from "flowbite-react";

function CacheDevToolsFakeController() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <div>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
            Dev Tools 🟡
          </Accordion.Title>
          <Accordion.Content>
            <div className="flex flex-col space-y-2">
              <Accordion.Panel>
                <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
                  Cache
                </Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col space-y-2">
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/cache/clear")}
                    >
                      Clear
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/cache/view")}
                    >
                      View
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/cache/settings")}
                    >
                      Settings
                    </Button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
                  Frontend
                </Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col space-y-2">
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/footer/simple")}
                    >
                      Simple
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/footer/complex")}
                    >
                      Complex
                    </Button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
                  Backend
                </Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col space-y-2">
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/backend/db/view")}
                    >
                      DB View
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/backend/db/backup")}
                    >
                      Backup
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/backend/db/restore")}
                    >
                      Restore
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/backend/server/start")}
                    >
                      Start
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/backend/server/stop")}
                    >
                      Stop
                    </Button>
                    <Button
                      size="xs"
                      onClick={() =>
                        handleNavigation("/backend/server/restart")
                      }
                    >
                      Restart
                    </Button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
                  API
                </Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col space-y-2">
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/api/view")}
                    >
                      View
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/api/test")}
                    >
                      Test
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/api/logs")}
                    >
                      Logs
                    </Button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
                  Auth
                </Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col space-y-2">
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/auth/login")}
                    >
                      Login
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/auth/logout")}
                    >
                      Logout
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/auth/register")}
                    >
                      Register
                    </Button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
              <Accordion.Panel>
                <Accordion.Title className="flex-col pl-6 overflow-hidden text-xs text-left text-ellipsis whitespace-nowrap">
                  Dev
                </Accordion.Title>
                <Accordion.Content>
                  <div className="flex flex-col space-y-2">
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/dev/logs")}
                    >
                      Logs
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/dev/settings")}
                    >
                      Settings
                    </Button>
                    <Button
                      size="xs"
                      onClick={() => handleNavigation("/dev/tools")}
                    >
                      Tools
                    </Button>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </div>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
}

export default CacheDevToolsFakeController;
