const workHistory = [
  {
    company: "gravidao",
    position: "Designer",
    year: "2018",
    description: "What was done",
  },
  {
    company: "bonusblock",
    position: "Engineer",
    year: "Current",
    description: "What is done",
  },
];

const WorkHistory = () => {
  return (
    <div>
      {workHistory.map((job, index) => (
        <div key={index}>
          <h3>{job.company}</h3>
          <p>
            {job.position} ({job.year})
          </p>
          <p>{job.description}</p>
        </div>
      ))}
    </div>
  );
};

export default WorkHistory;
