// src/components/CustomerLogoGrid.jsx
// Displayed on About -> Commercial (comapny logos)

import "./CustomerLogoGrid.css"; // Import the CSS file for additional styling if needed

const CustomerLogoGrid = () => {
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16">
        <h2 className="mb-8 text-3xl font-extrabold leading-tight tracking-tight text-center text-gray-900 lg:mb-16 dark:text-white md:text-4xl">
          You’ll be in good company
        </h2>
        <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 dark:text-gray-400">
          <a href="#" className="flex items-center justify-center">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-components.svg"
              alt="Company Logo"
              className="h-9 hover:text-gray-900 dark:hover:text-white"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-components.svg"
              alt="Company Logo"
              className="h-9 hover:text-gray-900 dark:hover:text-white"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-components.svg"
              alt="Company Logo"
              className="h-9 hover:text-gray-900 dark:hover:text-white"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-components.svg"
              alt="Company Logo"
              className="h-9 hover:text-gray-900 dark:hover:text-white"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-components.svg"
              alt="Company Logo"
              className="h-9 hover:text-gray-900 dark:hover:text-white"
            />
          </a>
          <a href="#" className="flex items-center justify-center">
            <img
              src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-components.svg"
              alt="Company Logo"
              className="h-9 hover:text-gray-900 dark:hover:text-white"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default CustomerLogoGrid;
